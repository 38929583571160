import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Navbar from './Components/Navbar'; 
import Home from './Components/Home'; 
import Footer from './Components/Footer';
import DetailedProgram from './Components/DetailedProgram';
import NavbarForProgramDetails from './Components/NavbarForProgramDetails';

const HomeLayout = () => (
  <>
    <Navbar />
    <Home />
  </>
);

const ProgramDetailsLayout = () => (
  <>
    <NavbarForProgramDetails />
    <DetailedProgram />
  </>
);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeLayout />} /> 
          <Route path="/ProgramDetails" element={<ProgramDetailsLayout />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
