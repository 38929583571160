import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid'; 
import logo from '../images/icold-logo-bannner.png'; 
import './Navbar.css'; 

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { 
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={`navbar-container ${isScrolled ? 'scrolled' : ''}`}
    >
      <Grid item>
        <img src={logo} alt="Navbar Logo" className={`logo ${isScrolled ? 'logo-scrolled' : ''}`} />
      </Grid>
    </Grid>
  );
}

export default Navbar;
