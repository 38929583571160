import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import './NavbarForProgramDetails.css'; // Import CSS file if needed

const NavbarForProgramDetails = () => {
  return (
    <Paper elevation={3} className="navbar-program-details">
      <Grid container >
        <Grid item>
          <Typography variant="h6">ICOLD 2023 - Program Overview - All Sessions in English</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NavbarForProgramDetails;
