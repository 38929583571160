import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider, Typography, Box } from '@mui/material';
import exampleImage from '../images/icold-logo-bannner.png';

const DetailedProgram = () => {
  const [imageHeight, setImageHeight] = useState(200);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newHeight = Math.max(100, 200 - scrollY / 5);
    setImageHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  return (
    <>

      <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />

      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ minHeight: '100vh', paddingTop: '10px', }}
      >
        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
          <img
            src={exampleImage}
            alt="Description of image"
            style={{ maxWidth: '100%', height: `${imageHeight}px` }}
          />
        </Grid>

        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10, backgroundColor: 'rgb(217 217 217)' } }} >
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Fri 27 Sept 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              08:00- 17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Registration:
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien. <br />
                Duration: 09:00 hrs. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 - 18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                ICOLD Board Meeting:
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien - Club Lounge 20th Floor. <br />
                Duration: 09:00 hrs. <br />
                Type of Program: ICOLD <br />
                Seating: 10 Persons
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              19:30 - 21:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Dinner of ICOLD Board & INCOLD Representative + Accompanying Person
              </Typography>
              <Typography variant="body1">
                Location:Restaurant Miso Sexy, 2nd floor,Ambawatta, Kaldas Marg,Mehrauli, New Delhi. <br />
                Duration: 01:30 hrs. <br />
                Type of Program: Social Event <br />
                Seating: 30 Persons
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10 } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Sat 28 Sept 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              08:00- 17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Registration:
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien. <br />
                Duration: 09:00 hrs. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 - 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                ICOLD Board Meeting:
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien - Inspire Hall. <br />
                Duration: 04:00 hrs. <br />
                Type of Program: ICOLD <br />
                Seating: 10 Persons
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:30 - 16:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                ICOLD Board & Technical Committee Chairman's Meeting
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien - Inspire Hall. <br />
                Duration: 02:00 hrs. <br />
                Type of Program: ICOLD <br />
                Seating: 60 Persons
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00 - 18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                ICOLD Board & Local Organizing Committee (LOC) Meeting
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien - Inspire Hall. <br />
                Duration: 01:00 hrs. <br />
                Type of Program: ICOLD <br />
                Seating: 25 Persons
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              19:30 - 22:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Dinner of ICOLD Board, LOC, Technical Committee Chairman's & Accompanying Persons
              </Typography>
              <Typography variant="body1">
                Location:Hotel Le Meridien - Inspire Hall. <br />
                Duration: 01:30 hrs. <br />
                Type of Program: Social Event <br />
                Seating: 50 Persons
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10, backgroundColor: 'rgb(217 217 217)' } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Sun 29 Sept 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              9:00 - 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - D Workshop on Workshop name:
              </Typography>
              <Typography variant="body1">
                1. Ageing Concrete Dams - Completion of Bulletin "Ageing Concrete Dams & Kickoff" Condition Assessment & Prediction of Remaining Service-life. <br />
                2. Sustainability of Concrete Dams, Design & Construction. <br />
                Location:MR 6. <br />
                Duration: Half Day <br />
                Seating: 60
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              9:00 - 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - P Workshop:
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Duration: Half Day <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              9:00 - 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - A Workshop on Assessment on Capitalization of the Benchmark Workshops result organized by the TC A Since 1991 up today
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Duration: Half Day <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-12:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - HWS  Workshop
              </Typography>
              <Typography variant="body1">
                Location:Room 19. <br />
                Duration: 03 Hrs <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC  - Q  Workshop on New Bulletin
              </Typography>
              <Typography variant="body1">
                Location:MR 8. <br />
                Duration: Half Day <br />
                Seating: 75
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-12:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - Z Workshop on Contractual Strategies/Comtractual Setup and critical Success Factors of the Contruction Contract
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Duration: 3 Hours  <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - L &H Joint Workshopon Example of risk assessment application
              </Typography>
              <Typography variant="body1">
                Location:MR 10. <br />
                Duration: 3 Hours  <br />
                Seating: 50-100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Workshop By YPF
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Duration: Half day  <br />
                Seating: 50
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - S Workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 16. <br />
                Duration: Half day  <br />
                Seating: 60
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00-17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - LE Workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 17. <br />
                Duration: Full Day<br />
                Seating: 20+
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC -U  Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 13. <br />
                Duration: Half Day<br />
                Seating: 40
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:00-14:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Lunch
              </Typography>
              <Typography variant="body1">
                Location:Exhibition Hall NO. 14 GF. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-16:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - I Workshop on Emergency Management State of Practice
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Duration: 2 Hrs<br />
                Seating: 60
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-18:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - ZA1 Workshop on World Declaration on the role of dam in the Energy Transition & Climate Change
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Duration: 01:30 hrs<br />
                Seating: 30-50
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - TRS Workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Duration: 1.5 Hrs<br />
                Seating: 50
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17: 00-19:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Young Professional Forum Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Duration: 1.5 Hrs<br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00- 17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - E Workshop on Dam Embankment Cracking Behaviour
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Duration: 3:00 Hrs<br />
                Seating: 100
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-16:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - ZA2 Workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Duration: 2 Hrs<br />
                Seating: 50
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:30-18:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - B Workshop on Nonlinear dynamic analysis of Embankment dams
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Duration: 2 Hrs<br />
                Seating: 20-40
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC-  L & Q Workshop on Surveillance & Monitoring for Tailings Facilities and Embankment Dams
              </Typography>
              <Typography variant="body1">
                Location:MR 8. <br />
                Duration: 3 hours<br />
                Seating: 50
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - F Workshop on New Bulletin
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Duration: Half Day <br />
                Seating: 25
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - H Workshop on Dam Safety
              </Typography>
              <Typography variant="body1">
                Location:MR 10. <br />
                Duration: Half Day <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - V workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Duration: 2 hours/Half Day <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - K  Workshop
              </Typography>
              <Typography variant="body1">
                Location:MR 16. <br />
                Duration: Half Day <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-16:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - T Workshop on Dams & Sustainability
              </Typography>
              <Typography variant="body1">
                Location:MR 13. <br />
                Duration: Half Day <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>




        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10 } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Mon 30 Sept 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -16:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - D Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Duration: Full Day. <br />
                Seating: 50
              </Typography>
            </Box>



          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -16:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - H Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Duration: Full Day. <br />
                Seating: 50
              </Typography>
            </Box>

          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -16:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - C Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Duration: Full Day. <br />
                Seating: 35
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - Q Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Duration: Half day. <br />
                Seating: 50
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-16:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - ZX4 Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Duration: 2 hours. <br />
                Seating: 12
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - LE Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 8. <br />
                Duration: Half Day. <br />
                Seating: 12
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC -Z  Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Duration: Half Day. <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - Y Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 10. <br />
                Duration: Full Day. <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - A Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Duration: Full Day. <br />
                Seating: 35
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - E Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 16. <br />
                Duration: Half day. <br />
                Seating: 35
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              08:00-17:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - L Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 17. <br />
                Duration: Full Day. <br />
                Seating: 20-50
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - J Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 11. <br />
                Duration: Full Day. <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - P Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 12. <br />
                Duration: Full Day. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - T Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 13. <br />
                Duration: Full Day. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - O Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 14. <br />
                Duration: Half Day. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - K Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 5. <br />
                Duration: Half Day. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - S Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 20. <br />
                Duration: Full day . <br />
                Seating: 30
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - HWS Meeting
              </Typography>
              <Typography variant="body1">
                Location:Bussiness Room 4 A. <br />
                Duration: Half day. <br />
                Seating: 07
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00 -17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - F Meeting
              </Typography>
              <Typography variant="body1">
                Location:BUSINESS ROOM 4 B. <br />
                Duration:Full day. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              08:30-12:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - N Meeting
              </Typography>
              <Typography variant="body1">
                Location:BUSINESS ROOM 4 C. <br />
                Duration:Half Day. <br />
                Seating:12-20
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-19:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Regional Club Aisa (APG)  meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Duration:2 Hours. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-19:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Regional Club Amercias (INCA) meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Duration:2 Hours. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-19:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Regional Club Africa (ARC) meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Duration:2 Hours. <br />
              </Typography>
            </Box>

          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-19:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Regional Club Europe meeting (Eurocold)
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Duration:2 Hours. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - V Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 8. <br />
                Duration:6 hours. <br />
                Seating: 20
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - I Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Duration:6 hours. <br />
                Seating: 35
              </Typography>
            </Box>
          </Box>


          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - M Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 16. <br />
                Duration:6 hours. <br />
                Seating: 40
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - B Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 14. <br />
                Duration:6 hours. <br />
                Seating: 20-25
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - ZA2 Meeting
              </Typography>
              <Typography variant="body1">
                Location:MR 5. <br />
                Duration:4 hours. <br />
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - G Meeting
              </Typography>
              <Typography variant="body1">
                Location:BUSSINESS ROOM 4 A. <br />
                Duration:6 Hrs. <br />
                Seating: 15
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:30-19:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                TC - TRS Meeting
              </Typography>
              <Typography variant="body1">
                Location:BUSINESS ROOM 4 C. <br />
                Duration:6 Hrs. <br />
                Seating: 15
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10, backgroundColor: 'rgb(217 217 217)' } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Tue 01 Oct 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 11:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                International Symposium (Opening Ceremony)
              </Typography>
              <Typography variant="body1">
                Location:Audi - 2. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30-13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1 A)Dam Safety & Engineering
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30-13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 A)Dam Engineering & Construction
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30-13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 A)
                Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30-13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 4 A)
                Dams & Climate Change Adaptation
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30-13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 5 A)
                Dam & People
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:30- 12:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Press Conference (Ministers, Board, INCOLD, Journalists)
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops - 4 (Dam Repairs & Rehabilitation- Deciding Design & Safety Criteria) in Collaboration with DRIP/CWC

              </Typography>
              <Typography variant="body1">
                Location:Audi - 2. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1B)
                Dam Safety & Engineering

              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 B)
                Dam Engineering & Construction

              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 B)
                Dam Rehabilitation & Improvement

              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 4 B)
                Dams & Climate Change Adaptation

              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 5 B)
                Dam & People

              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 6 A)
                Integrated Reservoir Management
              </Typography>
              <Typography variant="body1">
                Location:MR 10. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Workshop on Derna & Bu Mansur Dam Failure - Libya
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1 C) Dam Safety & Engineering

              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 C)Dam Engineering & Construction
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 C)Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 4 C)Dams & Climate Change Adaptation
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium(session 5 C)Dam & people
              </Typography>
              <Typography variant="body1">
                Location:MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 6 B)Integrated Reservoir Management
              </Typography>
              <Typography variant="body1">
                Location:MR 10. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              15:30-17:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Workshop on African Regional Initiative
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-18:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Francophone Delegate Meetings
              </Typography>
              <Typography variant="body1">
                Location:Room 7. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              17:00-18:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Francophone Delegate Meetings
              </Typography>
              <Typography variant="body1">
                Location:MR 15. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10 } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Wed 02 Oct 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 10:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1 D)
                Dam Safety & Engineering
              </Typography>
              <Typography variant="body1">
                Location:MR 6.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 10:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 D)
                Dam Engineering & Construction
              </Typography>
              <Typography variant="body1">
                Location: MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 10:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 D)
                Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location: MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 10:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 8 A)
                Evolving with Modern Technology for Construction of dam
              </Typography>
              <Typography variant="body1">
                Location: MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops - 1 (Application of Geosynthetics in Dam Engineering)
              </Typography>
              <Typography variant="body1">
                Location: MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:10
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops - 2 (Risk Informed Dam Safety Management) in collaboration with NDSA
              </Typography>
              <Typography variant="body1">
                Location: MR 10. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:00-14:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Lunch
              </Typography>
              <Typography variant="body1">
                Location: Exhibition Hall NO. 14 GF.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1 F)
                Dam Safety & Engineering
              </Typography>
              <Typography variant="body1">
                Location: MR 6. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 F)
                Dam Engineering & Construction
              </Typography>
              <Typography variant="body1">
                Location: MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 F)
                Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location: MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 F)
                Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location: Room 4. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-15:40
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium
                (Session 7 A)
                Environmental & Social Aspects
              </Typography>
              <Typography variant="body1">
                Location: MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshop 3 with TC Committee L  Design TSF for Closure
              </Typography>
              <Typography variant="body1">
                Location: MR 10. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 1 G)
                Safety & Engineering
              </Typography>
              <Typography variant="body1">
                Location: MR 6. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 2 G)
                Dam Engineering & Construction
              </Typography>
              <Typography variant="body1">
                Location: MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 3 G)
                Dam Rehabilitation & Improvement
              </Typography>
              <Typography variant="body1">
                Location: MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium (Session 9 B)
                Dam & Renewable Energy
              </Typography>
              <Typography variant="body1">
                Location: MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              16:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Symposium
                (Session 7 B)
                Environmental & Social Aspects
              </Typography>
              <Typography variant="body1">
                Location: MR 9. <br />
                Seating: 100
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px' }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10, backgroundColor: 'rgb(217 217 217)' } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Thu 03 Oct 2024
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                92nd ICOLD General Assembly Meeting at Leaders Lounge
              </Typography>
              <Typography variant="body1">
                Location: Leader Lounge. <br />
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Special Session on Innovative Financing of Dams Projects  In Collaboration with World Bank
              </Typography>
              <Typography variant="body1">
                Location:MR 6. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshop 7 (Pumped Pumped Hydropower Storage - The need to support high penetration of renewable energy)
              </Typography>
              <Typography variant="body1">
                Location:MR 7. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops -5 (Numerical analysis of dams) by INCOLD
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 13:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops  (Numerical analysis of dams) by INCOLD
              </Typography>
              <Typography variant="body1">
                Location:Room 4. <br />
                Seating:200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              09:00- 18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops -6 (Sedimentation Management in Reservoirs for Sustainable Development)
              </Typography>
              <Typography variant="body1">
                Location:MR 19. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops  (Numerical analysis of dams)
                in collaboration with MIDAS
              </Typography>
              <Typography variant="body1">
                Location:MR 18. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              14:00-18:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                INCOLD Workshops  (Numerical analysis of dams)
                in collaboration with MIDAS
              </Typography>
              <Typography variant="body1">
                Location:Room 3. <br />
                Seating: 200
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px', marginBottom: "10px" }} />
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={10}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#0067a5', color: '#fff' }}>
                    <TableCell sx={{ color: 'white' }}>S.No.</TableCell>
                    <TableCell sx={{ color: 'white' }}>Session Timing & No.</TableCell>
                    <TableCell sx={{ color: 'white' }}>Presentation</TableCell>
                    <TableCell sx={{ color: 'white' }}>Speaker</TableCell>
                  </TableRow>
                  {/* Themed Row */}
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 A) <br />
                      Chair: Shri Anil Jain, Chairman, NDSA <br />
                     
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>(11:30-11:45)</TableCell>
                    <TableCell>Strategies to manage GLOF risk in hydroelectric power projects – A case study of Teesta river basin in India</TableCell>
                    <TableCell>Anil Jain, CWC; Shiv Kumar Sharma, CWC</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>(11:45-12:00)</TableCell>
                    <TableCell>Building dam safety capability for the future: an essential part of your dam safety program</TableCell>
                    <TableCell>A.J. Ashworth, Entura</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>(12:00-12:15)</TableCell>
                    <TableCell>Dam safety evaluation during flood discharges in Slovakia</TableCell>
                    <TableCell>M. Minarik, Vodohospodarska vystavba SOE, Slovakia; A. Kasana; P. Panenka; E. Bednarova</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>(12:15-12:30)</TableCell>
                    <TableCell>Case studies in estimation of the probabilities of dam failure in quantitative risk assessment</TableCell>
                    <TableCell>C.F. Wan, D. Khoudair, N. Ostrovsky, GHD Pty Ltd, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>(12:30-12:45)</TableCell>
                    <TableCell>Integrated risk-informed framework for mine tailings storage facilities</TableCell>
                    <TableCell>S. Lacasse, Z.Q. Liu, L. Piciullo & A. Løkke, Norwegian Geotechnical Institute, Norway</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>6</TableCell>
                    <TableCell>(12:45-13:00)</TableCell>
                    <TableCell>Ensuring Dam Safety at Koldam HPS: Safety Compliances & Statistical Analysis</TableCell>
                    <TableCell>Akhilesh Chandra Joshi, NTPC Limited; Madhukar Aggarwal, NTPC Limited</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 B) <br />
                      Ms. Serafeina Lazaridou, President -ICOLD Europen Club (EURCOLD)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>7</TableCell>
                    <TableCell>(14:00-14:15)</TableCell>
                    <TableCell>Global registry of tailings storage facilities (v. 2024): overview and status update</TableCell>
                    <TableCell>Nahyan M. Rana Klohn Crippen Berger, Toronto, Canada Andy Small Klohn Crippen Berger, Fredericton, Canada Annika Bjelkevik Tailings Consultants Scandinavia, Sweden David Brett GHD, Tasmania, Australia Mauro Pio dos Santos Junior Pimenta de Avila Consultoria, Brazil Hervé K. Wa Kitambo WSP, Democratic Republic of Congo Ram Manohar Bishwal National Institute of Technology Rourkela, India Elias Baptista BCCexperts, Mozambique David John Cooling United Nations Environment Programme, Australia Stephen Barrie Church of England Pensions Board, United Kingdom</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>8</TableCell>
                    <TableCell>(14:15-14:30)</TableCell>
                    <TableCell>Stability of gravity dams. Discussion on verification criteria</TableCell>
                    <TableCell>F. Amberg, Lombardi Engineering Ltd., Bellinzona-Giubiasco, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>9</TableCell>
                    <TableCell>(14:30-14:45)</TableCell>
                    <TableCell>Static and dynamic monitoring of Cahora Bassa dam. Integrated use of HST and FE models</TableCell>
                    <TableCell>E. Carvalho HCB, Songo, Mozambique B. Matsinhe HCB, Songo, Mozambique S. Oliveira LNEC, Lisbon, Portugal P. Mendes ISEL-IPL, Lisbon, Portugal M. Rodrigues LNEC, Lisbon, Portugal A. Alegre Kinemetrics, Pasadena, CA, USA J. Proença CERIS-IST, Lisbon, Portugal</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>10</TableCell>
                    <TableCell>(14:45-15:00)</TableCell>
                    <TableCell>Integration of technology-driven datasets for Dam Safety Management and Maintenance planning</TableCell>
                    <TableCell>Harsh. Rathod., Ph.D. Niricson, Victoria, British Columbia, Canada Hetal Parmar WaterNSW, New South Wales, Australia T. Truong, P.E., PMP Yuba County Water Agency, Dobbins, California, USA</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>11</TableCell>
                    <TableCell>(15:00-15:15)</TableCell>
                    <TableCell>Surface Movement Comparison Between Field Observation and Design Specification of Tailings Storage Facility</TableCell>
                    <TableCell>Zubeyir Siregar PT Agincourt Resources, Batangtoru, Indonesia zsiregar@agincourtresources.com Anggi Hardian PT Agincourt Resources, Batangtoru, Indonesia Titik Astiwi PT Agincourt Resources, Batangtoru, Indonesia Devina Pascayulinda PT Agincourt Resources, Batangtoru, Indonesia Prianta Ginting PT Agincourt Resources, Batangtoru, Indonesia Syavira Indah Utami PT Agincourt Resources, Batangtoru, Indonesia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>12</TableCell>
                    <TableCell>(15:15-15:30)</TableCell>
                    <TableCell>Enhancing large dam operations using generative artificial intelligence</TableCell>
                    <TableCell>Lakshmin Bachu*, M.Sc., P.Eng., Principal Consultant, Engineering, Tetra Tech Canada Inc., Saskatoon, SK, Canada
                      *Vice-Chair, Communications Committee-Canadian Dam Association(CDA);National Director,
                      Canadian Water Resources Association-Saskatchewan (SK); and Board Member, Environment &
                      Water Committee, The Association of Consulting Engineering Companies (ACEC)-SK, Canada.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>13</TableCell>
                    <TableCell>(15:30-14:45)</TableCell>
                    <TableCell>Risk Assessment of Spillway Gates</TableCell>
                    <TableCell>J. Sathyamurthy & A. Mehta AtkinsRéalis, Gurugram, India A. Pandey & W. Lannin AtkinsRéalis, Vancouver, Canada</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 C) <br />
                      Shri R.K. Vishnoi, CMD THDC
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>14</TableCell>
                    <TableCell>(16:00-16:15)</TableCell>
                    <TableCell>Case-studies : Stakeholders risk exposure mitigation using ICOLD Bulletin 194, CDA and GISTM Guidelines</TableCell>
                    <TableCell>J-F St-Laurent SRK Consulting (Canada) Inc., Québec City, Canada T. Hadj-Hamou. SRK Consulting (USA) Inc., Reno, United State of America</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>15</TableCell>
                    <TableCell>(16:15-16:30)</TableCell>
                    <TableCell>Beyond closure: stewardship and risk management of a closed tailings storage facility</TableCell>
                    <TableCell>M. R. Sova - WSP Australia Pty. Ltd., Perth, Australia S. E. Orellana Palomino & B. N. Schmidt WSP USA Inc., Denver, United States of America C. Burton & K. Hamatake Barrick Gold of North America Inc., United States of America</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>16</TableCell>
                    <TableCell>(16:30-16:45)</TableCell>
                    <TableCell>Dam safety assessment in static and seismic conditions: the case of the Vajont Dam in Italy</TableCell>
                    <TableCell>E. Catalano, S.P. Teodori, R. Crapp Lombardi Engineering Ltd., Giubiasco, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>17</TableCell>
                    <TableCell>(16:45-17:00)</TableCell>
                    <TableCell>New Hazard Classification in England and Wales
                      Developing a new hazard classification for reservoir safety in
                      England and Wales</TableCell>
                    <TableCell>D Shaw Ove Arup and Partners Ltd, Cardiff, United Kingdom C French JBA Consulting, Skipton, United Kingdom A Morgan Ove Arup and Partners Ltd, Leeds, United Kingdom</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>18</TableCell>
                    <TableCell>(17:00-17:15)</TableCell>
                    <TableCell>Atlas InSAR: Dam deformation monitoring from Space</TableCell>
                    <TableCell>S.K. Palanisamy Vadivel, S. Amherdt, J. Lluch, M. Camafort, N. Devanthery, D. Albiol, B. Payas Sixense Iberia, Barcelona, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>19</TableCell>
                    <TableCell>(17:15-17:30)</TableCell>
                    <TableCell>The Evolution of Dam Safety Management at the Kainji Dam in Nigeria</TableCell>
                    <TableCell>C.R. Donnelly C. Richard Donnelly Consulting, Niagara on the Lake, Canada B. Albaji Mainstream Energy Solutions Limited, Borgu LGA, Nigeria B.Y. Nafama Mainstream Energy Solutions Limited, Borgu LGA, Nigeria</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>20</TableCell>
                    <TableCell>(17:30-17:45)</TableCell>
                    <TableCell>Distributed Fiber-optic strain measurements for monitoring of large structure in geotechnical and hydraulic engineering</TableCell>
                    <TableCell>Dr. Ajay Jha, Dr. Nils Nother, Dr. Massimo Facchini</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 D) <br />
                      Shri A.K SINGH
                      Ex-Chairman & Managing Director
                      NHPC Ltd. <br />
                      Mr. Alejandro Pujol,
                      Dam Specialist Controller Argentina
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>21</TableCell>
                    <TableCell>(09:00-09:15)</TableCell>
                    <TableCell>Calibration of a FEM model for a double curvature arch dam: addressing non-elastic displacements and climate challenges</TableCell>
                    <TableCell>C. Granell Spanish Commission On Large Dams, Granell Hydraulic Engineers, Madrid, Spain C.M. Baena & J. Milla Granell Hydraulic Engineers, Madrid, Spain A. Sigüenza & L. de la Torre & Y. Pérez & A. Vaquero & C. Mayoral Iberdrola, Madrid, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>22</TableCell>
                    <TableCell>(09:15-09:30)</TableCell>
                    <TableCell>Ageing Concrete Dams in a Seismically Active Environment (New Zealand)</TableCell>
                    <TableCell>V. Lal Damwatch Engineering Ltd, Wellington, New Zealand M. Okhovat Damwatch Engineering Ltd, Auckland, New Zealand S. McInerney Damwatch Engineering Ltd, Wellington, New Zealand</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>23</TableCell>
                    <TableCell>(09:30-09:45)</TableCell>
                    <TableCell>L-Band SAR derived soil moisture maps for the monitoring of earthen dams in Scandinavia</TableCell>
                    <TableCell>Jonathan Lynch, BSc, MSc, CGeol, FGS. Ground Engineering lead, ASTERRA, London, United Kingdom</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>24</TableCell>
                    <TableCell>(09:45-10:00)</TableCell>
                    <TableCell>Pore-pressure monitoring in the context of Norwegian dam safety regulation</TableCell>
                    <TableCell>A. Loekke, P. Sparrevik Norwegian Geotechnical Institute, Oslo, Norway G. Grzanic Norwegian Water Resources and Energy Directorate, Oslo, Norway</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>25</TableCell>
                    <TableCell>(10:00-10:15)</TableCell>
                    <TableCell>Interactive digital mapping of the RhÃ´ne Delta flood risk  from hazard studies to the development of a risk culture</TableCell>
                    <TableCell>T. MALLET General manager of SYMADREM, Arles, France</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>26</TableCell>
                    <TableCell>
                      (10:15-10:30)</TableCell>
                    <TableCell>Satellite based synthetic aperture radar dam applications, limitations and considerations</TableCell>
                    <TableCell>G. Hlepas
                      US Army Corps of Engineers, Washington DC, USA
                      J. Maurer
                      Missouri Science and Technology, Missouri, USA
                      W. Empson
                      Empson Consulting, Kansas, USA </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 E) <br />
                      Shri Janardan Choudhary,
                      Advisor, Adani Green Energy
                      Former Director (Technical), NHPC Ltd.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>27</TableCell>
                    <TableCell>(11:30-11:45)</TableCell>
                    <TableCell>Revisiting the shear strength criteria for rock discontinuities and the contact between rock and concrete for foundation assessment of concrete dams </TableCell>
                    <TableCell>M. Zoorabadi SMEC ANZ, Melbourne, Australia UNSW Sydney, Sydney, Australia F. Lopez SMEC ANZ, Melbourne, Australia D. Forster Middle Third Geological, Brisbane, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>28</TableCell>
                    <TableCell>(11:45-12:00)</TableCell>
                    <TableCell>Real-time geoelectrical monitoring for tailings storage facilities integrity: field-proven insights</TableCell>
                    <TableCell>G. Tresoldi LSI LASTEM, Milan, Italy A. Hojat Shahid Bahonar University of Kerman, Kerman, Iran L. Zanzi Politecnico di Milano, Milan, Italy,</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>29</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Acceptability criteria of physical stability of tailings dam based on numerical analysis</TableCell>
                    <TableCell>F. Leal, C. Barrueto, E. Santos & J. Campaña Geotechnical department, Arcadis, Santiago, Chile</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>30</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Hydro Tasmania's risk-based, systems approach to dam safety management</TableCell>
                    <TableCell>R. Herweynen Entura, Hobart, Australia P. Southcott Entura, Hobart, Australia N. Smith Hydro Tasmania, Hobart, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>31</TableCell>
                    <TableCell>

                      (12:30-12:45)</TableCell>
                    <TableCell>Large dams in Poland - current state and threats</TableCell>
                    <TableCell>G. Puchalska GPŚ Gabriela Puchalska, Wieliszew, Poland P. Śliwiński IMGW, Warszawa, Poland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>32</TableCell>
                    <TableCell>
                      (12:45-13:10)</TableCell>
                    <TableCell>A Numerical Investigation of Phreatic Surface Drain-down Under Gravity and Corresponding Time-Driven Stability Enhancement of Tailings Dam</TableCell>
                    <TableCell>Ankti Srivastava, PhD WSP India Private Limited, Noida, India Peter Chapman, PhD WSP Australia Private Limited, Perth, Australia</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 F) <br />
                      Shri Manoj Tripathi, Chairman, BBMB
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>33</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Beyond Conventional Practices. Enhanced Methodology for Dam Potential Failure Modes Screening.Full paper</TableCell>
                    <TableCell>I. Escuder-Bueno iPresas Risk Analysis and Polytechnic University of Valencia (UPV), Valencia, Spain A. Morales-Torres, F. D. García-Brook & H. C. Yarritu-Sánchez iPresas Risk Analysis, Valencia, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>34</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Deterministic and probabilistic sliding stability calculations of a buttress dam and a gravity dam under ice loading</TableCell>
                    <TableCell>F. Nadim, A. Løkke, U. Eidsvig, K. Robinson & S. Lacasse Norwegian Geotechnical Institute, Oslo, Norway A.H. Lundberg & E. Bretas Statkraft Energy AS, Oslo, Norway</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>35</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>A new numerical stress-strain model for the El Atazar dam (Spain) to inform dam operation and management </TableCell>
                    <TableCell>P. Trullás, J. L. García & D. Galán Canal de Isabel II, Madrid, Spain N. Soltani Universidad Politécnica de Valencia, Valencia, Spain I. Escuder-Bueno Universidad Politécnica de Valencia, Valencia, Spain iPresas Risk Analysis, Valencia, Spain A. Morales-Torres iPresas Risk Analysis, Valencia, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>36</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Remaining Lifespan of Large Dams </TableCell>
                    <TableCell>M. Wieland Chairman, ICOLD Committee on Seismic Aspects of Dam Design, Honorary Member of ICOLD, AFRY Switzerland, Zurich, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>37</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Practical Experience and Results of a Semi-Quantitative Risk Analysis for 55 Dams</TableCell>
                    <TableCell>S. Hoppe OFITECO, Madrid, Spain D. Ortiz Liriano OFITECO, Madrid, Spain A. Morales Torres ipresas, Valencia, Spain F.J. Romero Bermudez Guadalquivir River Basin Authority, Jaén, Spain J. Navarro Gimenez Guadalquivir River Basin Authority, Granada, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>38</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>International collaboration to advance levee management</TableCell>
                    <TableCell>M. Bitner U.S. Army Corps of Engineers Risk Management Center, Lakewood, CO, United States R. Lavery Environment Agency, Warrington, England N.J.A. Verdijck Rijkswaterstaat Water Transport and Environment, Utrecht, Netherlands</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Safety Management and Engineering(Session 1 G) <br />
                      Shri J Chandrashekhar Iyer, Former Chairman, CWC <br />
                   
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>39</TableCell>
                    <TableCell>(16:00-16:15)</TableCell>
                    <TableCell>Role of visual inspections and monitoring in asset management: Valuable learnings of DRIP scheme</TableCell>
                    <TableCell>Pramod NARAYAN Central Water Commission, Ministry of Jal Shakti, New Delhi, India Jürgen FLEITZ Ofiteco, Madrid, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>40</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Surveillance and rehabilitation of aging dams on the Drava River in Slovenia</TableCell>
                    <TableCell>P. Žvanut Slovenian National Building and Civil Engineering Institute, Ljubljana, Slovenia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>41</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>The new safety regulations for dams and reservoirs in Spain. Difficulties in its application.</TableCell>
                    <TableCell>M.P. Elías OFITECO, S.A., Alcobendas, Madrid, Spain J. Sánchez OFITECO, S.A., Alcobendas, Madrid, Spain</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>42</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Engineering resilience: a comparative analysis of dam safety strategies in diverse climates</TableCell>
                    <TableCell>A. Torabi Haghighi University of Oulu, Oulu, Finland A. Sharifi University of Oulu, Oulu, Finland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>42</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Assessment of institutional dam safety in Albania</TableCell>
                    <TableCell>G.R. Darbre Dam Safety Expert, Ittigen-Bern, Switzerland F. Maho Hydraulic Expert, Tirana, Albania A. Jovani Chairman, Albanian National Committee of Large Dams, Tirana, Albania</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>43</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Risk-informed insight to assess the safety of dams </TableCell>
                    <TableCell>S. Lacasse & K. Höeg Norwegian Geotechnical Institute, Oslo, Norway R.P.H. Wood Statkraft Energy AS, Oslo, Norway</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>44</TableCell>
                    <TableCell>
                      (17:30-17:45)</TableCell>
                    <TableCell>the role of legislation in ensuring dam safety â€“ global scenario</TableCell>
                    <TableCell>V. Saran, Central Water Commission
                      S. Sharan, Central Water Commission
                      A. Yapa, CPMU</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 A)  <br />
                      Shri Ghanshyam Prasad, Chairperson, Central Electricity Authority <br />
                      Dr. Enrique Cifres, Independent Consultant, Hon Vice President
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>45</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Subansiri Lower Project: design and construction challenges of the concrete cutoff wall</TableCell>
                    <TableCell>S. Mallet & G. Pereira
                      Soletanche Bachy, Rueil Malmaison, France
                      S. Arunachalam
                      Bachy Soletanche Engineering, Chennai, India</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>46</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>Importance of FEM analysis in arriving at structural system to deal with large lateral force due to creeping slope - A case study of Shongtong Karcham HEP (450 MW), India</TableCell>
                    <TableCell>N. S. Shekhawat Director, Central Water Commission, New Delhi, India M. S. Harshitha Deputy Director, Central Water Commission, New Delhi, India A. Suhail Assistant Director, Central Water Commission, New Delhi, India</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>47</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>A study on the potential effects of spectral matching on dynamic response of homogenous embankment dams</TableCell>
                    <TableCell>A. ASKARINEJAD * Swiss Federal Office of Energy, Bern, Switzerland * amin.askarinejad@bfe.admin.ch F. GALSTER Swiss Federal Office of Energy, Bern, Switzerland M. CÔTÉ Swiss Federal Office of Energy, Bern, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>48</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Weak rockfill in dam construction</TableCell>
                    <TableCell>G.T. Dounias Edafos Engineering Consultants SA, Athens, Greece S.G. Patsali Water Development Department, Nicosia Cyprus S.C. Sakellariou Edafos Engineering Consultants SA, Athens, Greece I.S. Kompogiorgas Edafos Engineering Consultants SA, Athens, Greece M.E. Bardanis Edafos Engineering Consultants SA, Athens, Greece</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>49</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>What could Tailings Facility Engineering look like in 2030?</TableCell>
                    <TableCell>C.A. Small Klohn Crippen Berger, Fredericton, New Brunswick, Canada A.D. Witte Klohn Crippen Berger, Vancouver, British Columbia, Canada A.G. Bjelkevik Tailings Consultants Scandinavia AB, Stockholm, Sweden</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>50</TableCell>
                    <TableCell>
                      (12:45-13:00)</TableCell>
                    <TableCell>Hot Water Springs in concrete dam foundation: Impact analysis and mitigation measures</TableCell>
                    <TableCell>R.K. Vishnoi, Bhupender Gupta, Sandeep Singhal, U.D. Dangwal, Indu Pal THDC India Limited, Rishikesh, India</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 B)  <br />
                      Prof. em. Dr. Anton J. Schleiss
                      Hon. President ICOLD
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>51</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>The importance of dam foundation grouting - The case of Tersefanou dam in Cyprus</TableCell>
                    <TableCell>S.G. Patsali Water Development Department, Nicosia Cyprus G.T. Dounias Edafos Engineering Consultants SA, Athens, Greece</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>52</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Sustainable sediment management in dams: design guidelines from selected case studies</TableCell>
                    <TableCell>F. Saba Bonilla, F.G. Besseghini, F. Tognola Lombardi SA, Bellinzona-Giubiasco, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>53</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Site C Clean Energy Project: Planning, Design and Construction Overview</TableCell>
                    <TableCell>A. Watson, P.Eng. BC Hydro, Vancouver, Canada M. Clark, P.Eng. BC Hydro, Vancouver, Canada R. Whittaker, P.Eng. BC Hydro, Vancouver, Canada D. Dowler, P.Eng. BC Hydro, Vancouver, Canada P. Finnegan, P.Eng. BC Hydro, Vancouver, Canada JF. Lord, P.Eng. BC Hydro, Vancouver, Canada D. McEachern, P.Eng. BC Hydro, Vancouver, Canada</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>54</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Grout curtain of Gura Apelor Dam in complex geological conditions</TableCell>
                    <TableCell>D. Stematiu Technical Sciences Academy of Romania, Bucharest, Romania I. Iacob SPEEH HIDROELECTRICA S.A., Bucharest, Romania R. Sarghiuta Technical University of Civil Engineering, Bucharest, Romania</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>55</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Scour Assessment of a Plunge Pool Applying Numerical, Physics-Based and Pressure-Fluctuation Based Semi-Empirical Methods</TableCell>
                    <TableCell>Fakhreddin Takhtemina SMEC, Melbourne, Australia Cameron Fraser SMEC, Melbourne, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>56</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Fuse Plug Spillway of Proyecto Multiple Montegrande</TableCell>
                    <TableCell>J.R.M. Almeida Intertechne Consultores S.A, Curitiba, Brazil R.Grube Intertechne Consultores S.A, Curitiba, Brazil R.E. Bertol Intertechne Consultores S.A, Curitiba, Brazil J.F.P. Machado Intertechne Consultores S.A, Curitiba, Brazil</TableCell>
                  </TableRow>

                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 C)  <br />
                      Sara Töyrä’s
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>57</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Pseudo static and Numerical Analysis for Seismic Assessment of  Concrete Gravity Dam of Vishnugad Pipalkoti Hydro-electric  Project, India </TableCell>
                    <TableCell>R.K.Vishnoi, CMD; Bhupender Gupta, D(T); Atul Jain, ED (Tech); U.D.Dangwal, AGM; Sumedh Nagrale, Manager THDC India Limited, Rishikesh, India</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>58</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>GERD, Stepped spillway perfomance under heavy operation</TableCell>
                    <TableCell>G. Pietrangeli, A. Bezzi, G.M. Beltrami Studio Pietrangeli, Roma, Italy</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>59</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Challenges and lessons learned from the delivery of Rookwood Weir</TableCell>
                    <TableCell>T.A. Williamson, J. Apostolidis & N. Hamilton Rookwood Weir Alliance - GHD, Brisbane, Queensland, Australia I. Goñi & T. Cao Sunwater, Brisbane, Queensland, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>60</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Assessing the brittleness of tailings â€“ some common pitfalls and uncertainties</TableCell>
                    <TableCell>D. Reid The University of Western Australia, Perth, Australia R. Fanni WSP, Perth, Australia A.B. Fourie The University of Western Australia, Perth, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>61</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Design and construction of Site C earthfill dam</TableCell>
                    <TableCell>M. Afif, P.Eng. AtkinsRéalis, Montreal, Canada B. Benabdellah, M.Sc.A., P.Eng. AtkinsRéalis, Vancouver, Canada J. Mendieta, P.Eng. AtkinsRéalis, Vancouver, Canada G.W. Stevenson, P.Eng., P.Geo. Klohn Crippen Berger Ltd., Vancouver, Canada A. Watson, M.Eng., P.Eng. BC Hydro, Vancouver, Canada</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>62</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Cloud-based Digital Scour Design at Dam Spillways</TableCell>
                    <TableCell>E.F.R. Bollaert AquaVision Engineering, Lausanne, Switzerland</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>63</TableCell>
                    <TableCell>
                      (17:30-17:45)</TableCell>
                    <TableCell>Numerical investigation of energy dissipation in stepped spillway using various multiphase models.</TableCell>
                    <TableCell>B.R. Pandey & G. Zenz Institute of Hydraulic Engineering and Water Resources Management, TU Graz, Graz, Austria M.R. K C & B. Crookston Utah Water Research Laboratory, Utah State University, Utah, USA</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 D)  <br />
                      Shri Rakesh Kumar Khali, Vice President - Operations Tunnel & UG Works, G R Infraprojects Ltd.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>64</TableCell>
                    <TableCell>
                      (09:00-09:15)</TableCell>
                    <TableCell>Management of the Early Impounding Phases of the Grand Ethiopian Renaissance Dam in Ethiopia</TableCell>
                    <TableCell>R. Rizzatti de Moraes Tractebel Engie, Paris, France O. Jullien Tractebel Engie, Paris, France V. Boinay Tractebel Engie, Paris, France K. Horo Ethiopian Electric Power, Addis Abeba, Ethiopia E. Woledkidan Ethiopian Electric Power, Addis Abeba, Ethiopia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>65</TableCell>
                    <TableCell>
                      (09:15-09:30)</TableCell>
                    <TableCell>Unintended Results and Lessons Learned from Altus Dam Safety Modification </TableCell>
                    <TableCell>Kent Walker US Bureau of Reclamation, Denver, USA Chris Slaven US Bureau of Reclamation, Denver, USA</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>66</TableCell>
                    <TableCell>
                      (09:30-09:45)</TableCell>
                    <TableCell>Prediction of pore water pressure, surface, and subsurface deformation for tailings storage facility ultimate design and compliance specifications: a case study of martabe gold mine tailings dam</TableCell>
                    <TableCell>Devina Pascayulinda PT Agincourt Resources, Tapanuli Selatan, Indonesia devina.pascayulinda@agincourtresources.com Anggie Hardian PT Agincourt Resources, Tapanuli Selatan, Indonesia Prianta Ginting PT Agincourt Resources, Tapanuli Selatan, Indonesia Aris Tambunan PT Agincourt Resources, Tapanuli Selatan, Indonesia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>67</TableCell>
                    <TableCell>
                      (09:45-10:00)</TableCell>
                    <TableCell>Design for resilient performance of hydraulic structures</TableCell>
                    <TableCell>P. Wangchuk Druk Green Power Corporation Ltd, Thimphu, Bhutan B.Pandey & G. Zenz Institute of Hydraulic Engineering and Water Resources Management, Graz University of Technology, Austria</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>68</TableCell>
                    <TableCell>
                      (10:00-10:15)</TableCell>
                    <TableCell>Measures to control thermal cracking in the riverbed discharge facilities of the Asuwagawa Dam</TableCell>
                    <TableCell>Shinichi Aoyama, Hiroshi Yamaguchi, Youichi Masai, Tsuyoshi Hiratsuka & Tomoyoshi Shikada, Shimizu Corporation, Tokyo, Japan Tomonori Kawabata Ministry of Land, Infrastructure, Transport and Tourisum Kinki Regional Development Bureau, Fukui prefecture, Japan</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>69</TableCell>
                    <TableCell>
                      (10:15-10:30)</TableCell>
                    <TableCell>Quality Assurance ang Quality Control Process at Tailings Storage Facility Construction of Martabe Gold Mine </TableCell>
                    <TableCell>Ira Swara Febyola Manik PT Agincourt Resources, Tapanuli Selatan, Indonesia Ira.manik@agincourtresources.com Ricardo Karo Karo PT Agincourt Resources, Tapanuli Selatan, Indonesia Hendra Fachroza PT Agincourt Resources, Tapanuli Selatan, Indonesia Anggie Hardian PT Agincourt Resources, Tapanuli Selatan, Indonesia Prianta Ginting PT Agincourt Resources, Tapanuli Selatan, Indonesia Aris Tambunan PT Agincourt Resources, Tapanuli Selatan, Indonesia</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 E)  <br />
                      Shri Ranendra Sarma, Director (techncial), NEEPCO <br />
                      Dr. Aries Feizal Firma, Vice Chairman , Indonesian Committee on Large Dams.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>70</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Numerical Simulation of FundÃ£o Dam using NorSand model </TableCell>
                    <TableCell>Dr. -Ing. S.S. Nagula Norwegian Geotechnical Institute, Oslo, Norway Dr. H. Liu Norwegian Geotechnical Institute, Oslo, Norway Dr. H.P. Jostad Norwegian Geotechnical Institute, Oslo, Norway Dr. L. Piciullo Norwegian Geotechnical Institute, Oslo, Norwa</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>71</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>Three dimensional Limit Equilibrium Stability Assessments â€“ Case Study Analysis and Application to Embankments </TableCell>
                    <TableCell>R.J. Mulji WSP, Perth, Australia P.J. Chapman WSP, Perth, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>72</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Increasing storage volume of disused quarries by dams for accommodating a 100 MW Glyn Rhonwy PSP </TableCell>
                    <TableCell>B. Stabel Fichtner GmbH & Co. KG, Stuttgart, Germany T. Clegg Fichtner Consulting Engineer Ltd., Stockport, Cheshire, United Kingdom</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>73</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Construction of large surge chambers, surges shafts and tailrace tunnels outfall slope in complex geological conditions</TableCell>
                    <TableCell>R.K. Vishnoi THDCIL, Rishikesh, India B. Gupta THDCIL, Rishikesh, India L.P. Joshi THDCIL, Tehri, India A. Jain THDCIL, Rishikesh, India N. Feknous Tractebel Engineering, Paris, France</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>74</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Geological challenges faced in construction of concrete dam and their solution: - A case study from   Punatsangchhu-II (1020MW), Hydro Electric Project, Bhutan</TableCell>
                    <TableCell>Arun Kumar1 , Rajesh Chandel2 , Ajay Kumar3 , Thinley Dorji4 1.Sr. Resident Geologist (GSI), Punatsangchhu-II Hydroelectric Project, Bhutan2. Managing Director, Punatsangchhu-II Hydroelectric Project, Bhutan3. Director (Technical), Punatsangchhu-II Hydroelectric Project, Bhutan4 , Joint Managing Director, Punatsangchhu-II Hydroelectric Project, Bhutan</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>75</TableCell>
                    <TableCell>
                      (12:45-13:10)</TableCell>
                    <TableCell>Construction of Vishnugad-Pipalkoti HE Project (444MW) in highly adverse geological conditions and very remote location with innovation - A Case Study of Dam Excavation in steep terrain in Helong area of District Chamoli, Uttarakhand, India</TableCell>
                    <TableCell>R. K. Khali Vice President Operations-Tunnel & UG Works, Hydro Power, G R Infraprojects Limited, Gurgaon, Haryana, India-122002. Email: rakesh.khali@grinfra.com S. Kumar Project Manager, Hindustan Construction Company Limited, Mumbai, India-400083</TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 F)  <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>76</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Implementation of Dry Stack Tailing Facilities (DSTF) in Indonesia</TableCell>
                    <TableCell>A. Rinaldi* Technical Implementation Unit for Dam Engineering, Ministry of Public Works and Housing, South Jakarta, Indonesia *Email: aris.rinaldi@pu.go.id P. N. Ginting TSF Manager, PT. Agincourt Resources, South Tapanuli, Indonesia H. Jitno Member of INACOLD and Lecturer, Institut Teknologi Nasional, Bandung, Indonesia A. F. Firman Member of Indonesian Dam Safety Commission, South Jakarta, Indonesia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>77</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Enhancing the sustainability and efficiency of tarapezoidal CSG dams: An innovative approach using nonstandard fly ash</TableCell>
                    <TableCell>T. Ishida Tokyo University, Tokyo, Japan T. Abe Naruse Dam Construction Office, Higashi-Naruse, Japan N. Yasuda, Y. Yamaguchi Japan Dam Engineering Center, Tokyo, Japan & T. Suzuki Kei Co., Ltd., Tokyo, Japan</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>78</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Sliding stability of arch dams using FEA and displacement criterion</TableCell>
                    <TableCell>S.L. Lalli, F. Lopez & M. McKay SMEC Australia Pty. Ltd., Melbourne, Australia</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>79</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Calibrated numerical modelling of the stress-strain response of the 275 m high Yusufeli concrete arch dam during first filling</TableCell>
                    <TableCell>R.O. Cassells ARQ Dams, Pretoria, South Africa Q.H.W. Shaw ARQ Consulting Engineers Limited, Mauritius F.V. Ҫevik Limak Inşaat A.Ş., Ankara, Turkey D. Aydoğan General Directorate of State Hydraulic Works, Ankara, Turkey</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>80</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Modelling natural convection in a rockfill dam in Sweden</TableCell>
                    <TableCell>J. Sundin & S. Johansson HydroResearch, Täby, Sweden C.-O. Nilsson Uniper – Sydkraft Hydropower AB, Östersund, Sweden</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>81</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Montegrande Project â€“ Main Aspects of Design</TableCell>
                    <TableCell>A.M. Calcina
                      Intertechne Consultores S.A, Curitiba, Brazil
                      C. Abrão Junior
                      Abrão Engenharia e Consultoria, São Paulo, Brazil
                      S.B. Katereniuk
                      Intertechne Consultores S.A, Curitiba, Brazil
                      M.C.L. Carvalho
                      Intertechne Consultores S.A, Curitiba, Brazil
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Engineering & Construction (Session 2 G)  <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>82</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Dam Seat Scanning Through Seismic Tomography and Assessment of Engineering Properties of Rock mass</TableCell>
                    <TableCell>M. A. K. P. Singh Member (Hydro), CEA, India, makp.singh@gov.in S. L. Kapil, Former Executive Director, NHPC, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>83</TableCell>
                    <TableCell>

                      (16:15-16:30)"</TableCell>
                    <TableCell>Assessment of seismic resilience of aging concrete gravity dams</TableCell>
                    <TableCell>esharee Patra1 , Ashutosh Bagchi1 1 Department of Building, Civil & Environmental Engineering, Concordia University, Montreal, Canada Pramod Narayan2 2 Central Water Commission, New Delhi, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>84</TableCell>
                    <TableCell>

                      (16:15-16:30)</TableCell>
                    <TableCell>Assessment of seismic resilience of aging concrete gravity dams</TableCell>
                    <TableCell>esharee Patra1 , Ashutosh Bagchi1 1 Department of Building, Civil & Environmental Engineering, Concordia University, Montreal, Canada Pramod Narayan2 2 Central Water Commission, New Delhi, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>85</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Unpacking the critical imperatives in financing transboundary hydro power projects and infrastructure</TableCell>
                    <TableCell>E.M. Kabwe, Zambezi River Authority, Lusaka, Zambia S.Z. Mhlanga Zambezi River Authority, Lusaka, Zambia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>86</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Application of Construction Information Model for RFC Dams (CIM4R) in Yongfeng RFC Arch Dam</TableCell>
                    <TableCell>F. JIN Tsinghua University, Beijing, China J.Q. YANG Tsinghua University, Beijing, China H. ZHOU Tsinghua University, Beijing, China H.P. Luo Sichuan Ciminfotech Co., Ltd., Meishan, China X.X. ZHANG Sichuan Ciminfotech Co., Ltd., Meishan, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>87</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Bold, Innovative and Impressive Solutions for a Large Embankment Dam with engineering challenges: Tehri Dam </TableCell>
                    <TableCell>Mukuteswara GOPALAKRISHNAN
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>88</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Research on anchor support characteristics and parameter optimization of small-section TBM tunnels</TableCell>
                    <TableCell>WANG Wei1 , YANG Guang2,3* , REN Taozhe1 , DU Kenan1 , LV Fengying1 , WANG Junjie2,3 (1. State Grid Xin Yuan Company Limited, Beijing 100032, China; 2. State Key Laboratory of Shield Machine and Boring Technology, Zhengzhou 450001, China; 3. China Railway Tunnel Bureau Group Co. Ltd., Guangzhou 511466, China)
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 A) <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>89</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Waterproofing and Strengthening in Masonry and Concrete Dam in underwater conditions.</TableCell>
                    <TableCell>Mr. Rajesh Pandey & Mr. Soumyadip Pramanik M/s Dynasoure Concrete Treatment Pvt. Ltd, Mumbai, Maharashtra, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>90</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>A simple approach for the safe design of spillways at small dams with special focus on the discharge channel, including the use of gabion drop structures</TableCell>
                    <TableCell>H. Anderson Verve Civil Eng (Pty) Ltd, Pretoria, South Africa
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>91</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>The Introduction of an emergency gate on Kariba Dam during its rehabilitation</TableCell>
                    <TableCell>S.D. Mukuwe Zambezi river Authority, Kariba, Zimbabwe P. Sipatela Zambezi river Authority, Kariba, Zimbabwe M.C. Munodawafa Zambezi river Authority, Kariba, Zimbabwe N. Crosby KGAL, London, England
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>92</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Shared Challenges and Future Perspective revealed in the India-Japan Joint Workshop on Dam Management Issues including Dam Inspection and Water Efficiency</TableCell>
                    <TableCell>Takahiro Konami Water and Disaster Management Bureau, Ministry of Land, Infrastructure, Transport and Tourism (MLIT) Japan Mr. Padma Dorje Gyamba Central water Commission (CWC), Ministry of Jal Shakti The Republic of India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>93</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Safety reassessment of old dams and implementation of measures for continued safe operations</TableCell>
                    <TableCell>A. Maita Statkraft Peru SA, Lima, Peru E. Bretas Statkraft Energi AS, Oslo, Norway
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>94</TableCell>
                    <TableCell>
                      (12:45-13:00)</TableCell>
                    <TableCell>Drilling and Cleaning Foundation Drainage Holes of Dharoi Dam </TableCell>
                    <TableCell>G.H. Ninama Assistant Engineer, W.R.D. Gujarat , Dharoi Dam , Gujarat, India M.D. Patel Chief Engineer & Additional Secretary ( North Gujarat ) , W.R.D. Gujarat , Gujarat , India
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 B) <br />
                      Prof Ali Noorzad
                      Hon. Vice President, ICOLD & President, IRCOLD
                      Iran
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>95</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Grouting to reduce seepage at Neckartal Dam</TableCell>
                    <TableCell>R. Greyling, E. Lillie, D. Mouton Knight Piésold Consulting, Pretoria, South Africa P. Segatto Rostruct, Johannesburg, South Africa
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>96</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Integrating rain-on-grid modelling to enhance the development of tailings storage facility surface water closure plans</TableCell>
                    <TableCell>R.P. van der Colf, C.R. Bolton, I.R. Ilott & J.M. Walls SRK Consulting, Johannesburg, South Africa
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>97</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>CLIMATE CHANGE: THE REHABILITATION OF DAMS</TableCell>
                    <TableCell>P. Sharma WSP, Noida, India P. Dutta WSP, Bangalore, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>98</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Upgrading Miraflores Dam: Enhancing Stability and Performance Under Seismic Loads</TableCell>
                    <TableCell>C. Marulanda1 , A. Marulanda1 , W. Ruíz1 , C. Cardona2 and J. Ramos2 1 INGETEC, Colombia, 2 Empresas Públicas de Medellín EPM, Colombia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>99</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>State-of-the-art review of embankment dam safety and rehabilitation</TableCell>
                    <TableCell>Debdip Das Indian Institute of Technology Roorkee, Uttarakhand, India Ravi S. Jakka Indian Institute of Technology Roorkee, Uttarakhand, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>100</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Rehabilitation works in the dry and underwater</TableCell>
                    <TableCell>Jagadeesan. Subramanian Carpi India, Chennai, Tamil Nadu, India G. Vaschetti Carpi Tech, Balerna, Ticino, Switzerland
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 C) <br />
                      Shri Bhopal Singh, DG, NWDA <br />
                      Carlos Granell Ninot, President, SPANCOLD
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>101</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Infinitus by Encardio Rite: The Total Dam Safety Solution x</TableCell>
                    <TableCell>Ritvick Bhalla Innovation and Growth Manager, Encardio Rite Group, San Francisco, United States of America Prateek Mehrotra Executive Vice President, Encardio-rite Electronics Pvt. Ltd., Lucknow, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>102</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Remedial works of the erosional caverns in the rear sill of the Iron Gates I Dam</TableCell>
                    <TableCell>D.Covaceanu Hydroproiect Design, Bucharest, Romania A.Dadu Hidroelectrica, Bucharest, Romania D.Stematiu Romanian Academy of Technical Sciences, Bucharest
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>103</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Grouting issues for rehabilitation of Ukai spillway block under DRIP</TableCell>
                    <TableCell>Shri. M. R. Patel Chief Engineer (SG) & Addl. Secretary, WRD, Govt. of Gujarat, India Dr. Sharad G. Joshi Member, Dam Safety Review Panel, DRIP, Govt. of Gujarat, India Shri. G. S. Kokni Deputy Exe. Engineer, Ukai Division No. 1, WRD, Govt. of Gujarat, India Shri. J. R. Jariwala Assistant Engineer, Ukai Division No. 1, WRD, Govt. of Gujarat, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>104</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Refurbishing works of the mechanical equipment at some Romanian Large Dams</TableCell>
                    <TableCell>I. D. Asman ROCOLD, Bucharest, Romania A. Abdulamit Technical University of Civil Engineering, Bucharest, Romania B. Creanga National Administration Romanian Waters, Siret Branch, Bacau, Romania T. Pisculidis National Administration Romanian Waters, Somes – Tisa Branch, Cluj - Napoca, Romania
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>105</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Rehabilitation and Improvement of 100 year old Krishnaraja Sagar dam,Karnataka,India.</TableCell>
                    <TableCell>Madhava Chief Engineer&Nodal officer(Retd) & Consultant,SPMU,DRIP,WRD, Karnataka,India Kishore kumar Assistant Executive Engineer,No.2, Gauging Sub division, KRS,MandyaDistrict,Karnataka,India. Rajesh Pondey M/S. Dynasoure Concrete Treatment Private Ltd, Mumbai,India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>106</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Indiaâ€™s ageing large dams â€“summary of DRIP rehabilitation measures.</TableCell>
                    <TableCell>Pramod Narayan Director, Central Water Commission, New Delhi, India D.C. Froehlich Consulting Engineer, Cary, North Carolina, USA
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 D) <br />
                      Shri Anand Mohan, Joint Secretary (RD & PP), MoWR<br />
                      Martin Wieland, ICOLD
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>107</TableCell>
                    <TableCell>
                      (09:00-09:15)</TableCell>
                    <TableCell>Italian dam permanent observatory
                      EXPERIENCES AND IMPROVEMENTS OF THE ITALIAN DAMS REHABILITATION
                      PERMANENT OBSERVATORY</TableCell>
                    <TableCell>Francesco Fornari Enel Green Power, Italy Ezio Baldovin Geotecna Progetti , Italy Rinaldo Murano M.I.T. - Direzione Generale Dighe e Infrastrutture Idriche, Italy
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>108</TableCell>
                    <TableCell>
                      (09:15-09:30)</TableCell>
                    <TableCell>sedimentation is the bigges challange in reservoir management. The most effective treatment is to carry out treatment upstream with construction of retaining dams.It can will be able to be effectively to further reduce rate of sedimentation of sutami dam</TableCell>
                    <TableCell>Heddy Bramantya Jasa Tirta Public Corporation I, Malang, Indonesia Ariet Setiawan Jasa Tirta Public Corporation I, Malang, Indonesia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>109</TableCell>
                    <TableCell>
                      (09:30-09:45)</TableCell>
                    <TableCell>Discharge rating curve generation using CFD in an AI-driven automated numerical laboratory</TableCell>
                    <TableCell>Pankaj Lawande Flow Science Software Private Limited, Pune, India Anurag Chandorkar Flow Science Software Private Limited, Pune, India John Wendelbo Flow Science Inc., Santa Fe, USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>110</TableCell>
                    <TableCell>
                      (09:45-10:00)</TableCell>
                    <TableCell>Rehabilitation of Cedar Cliff dam with giant Fusegates, Cullowhee, North Carolina, USA</TableCell>
                    <TableCell>E.E. Rabias Hydroplus, Paris, Greece B. Keaton Duke Energy,USA R. Makela Barnard Construction Company, Inc., USA B. Chrisman HDR, Inc., USA H. Kocahan Hydroplus, Inc., USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>111</TableCell>
                    <TableCell>
                      (10:00-10:15)</TableCell>
                    <TableCell>Labyrinth and Piano Key Weirs in Cold Climate-Experiences from Prototype Facilities and Laboratories</TableCell>
                    <TableCell>James Yang R&D Hydraulic Laboratory, Vattenfall AB, Älvkarleby, Sweden Civil & Architectural Engineering, KTH Royal Institute of Technology, Stockholm, Sweden Erik Nordström R&D Hydraulic Laboratory, Vattenfall AB, Älvkarleby, Sweden Civil & Architectural Engineering, KTH Royal Institute of Technology, Stockholm, Sweden Shicheng Li Civil & Architectural Engineering, KTH Royal Institute of Technology, Stockholm, Sweden Hasan Kocahan Hydroplus Inc., Palmetto, Florida, USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>112</TableCell>
                    <TableCell>
                      (10:15-10:30)</TableCell>
                    <TableCell>Model studies for adopting strengthening measures of plunge pool at Koldam power station</TableCell>
                    <TableCell>Madhukar Aggarwal1 · Rakesh Rathee2 ·
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 E) <br />
                      Dr. Kaley Crawford Flett, Ph.d (Civil) <br />
                      Honorary Academic, Chair, New Zealand Society on Large Dams (NZSOLD)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>113</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Application of Compact Hard Rock TBM with Ultra-small Turning Radius in Jinyun Pumped-storage Power Station</TableCell>
                    <TableCell>ZHOU WENHAI1
                      , QU DAWEI1
                      China Northwest Water Conservancy & Hydropower Engineering Consulting Co., Ltd., Xi'an, Shaanxi 710000
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>114</TableCell>
                    <TableCell>

                      (11:45-12:00)</TableCell>
                    <TableCell>Investigation of the materiala and vibration properties of a heightened conrete gravity dam</TableCell>
                    <TableCell>N. Yasuda Japan Dam Engineering Center (JDEC), Tokyo, Japan Z. Cao J-POWER Business Service Corporation, Tokyo, Japan T. Mano Tokachi General Promotion Bureau, Hokkaido Prefecture, Obihiro, Japan & T. Nishimura The Ikushyun-betsu River Dam Construction Office, MLIT, Hokkaido, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>115</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Harnessing the Power of Geophysics for Dam Rehabilitation and Improvement</TableCell>
                    <TableCell>S. Rana PARSAN Overseas Pvt. Ltd., New Delhi, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>116</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Evolutional rehabilitation concept and features for heritage dams</TableCell>
                    <TableCell>M. Safi Shahid Beheshti University, Tehran, I.R. Iran A. Noorzad Shahid Beheshti University, Tehran, I.R. Iran M.R. Poursaber Shahid Beheshti University, Tehran, I.R. Iran
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>117</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Leakage detection in the Buyo dam intake structure with a ROV</TableCell>
                    <TableCell>J.R. Courivaud EDF, La Motte Servolex, France P. Thomas Ribérac, France Y. Auer Satif OA, La Talaudière, France J.C. Kadjo CIE, Abidjan, Ivory Coast
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>118</TableCell>
                    <TableCell>
                      (12:45-13:10)</TableCell>
                    <TableCell>Sedimentation Control with Sedimentation Inflow Forecasts in the Sutami Reservoir, Indonesia</TableCell>
                    <TableCell>Heddy Bramantya Jasa Tirta Public Corporation I, Malang, Indonesia Ariet Setiawan Jasa Tirta Public Corporation I, Malang, Indonesia
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 F) <br />
                      Shri M. Gopalakrishnan, Ex. Member, CWC <br />
                      Dr Dong Soon Park
                      Ph.D, Head Researcher
                      K-water Research Institute
                      KNCOLD
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>119</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Cut-off walls using jet grouting as well as drilling and grouting work for hydropower projects in India, Bhutan, Nepal and Lebanon</TableCell>
                    <TableCell>Michael Baltruschat, Dirk Beier, Georges Abdo, Mazin Adnan, Vijayaranya Bandi, Yogeshwaran Purushothaman, Bauer Engineering
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>120</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Methodical and Incorporated testing procedure for design of  Shotcrete Mix Design for lessening the seepage in Masonry  Gravity Dams â€“ Case Study</TableCell>
                    <TableCell>Dr. Sarbjeet Singh, Scientist C, CWPRS, Pune, India Mr.R. Vigneswaran, Scientist C, CWPRS, Pune, India Mr.Jai Prakash Meena, RA, CWPRS, Pune, India Mr. Ankit Sahu, RA, CWPRS, Pune, India Mr. Lalit Kumar, Scientist B, CWPRS, Pune, India Dr. R S Kankara, Director, CWPRS, Pune, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>121</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Systematic, sequential, and integrated approach for arresting seepage by cementitious grouting of masonry gravity dams â€“ CW&PRS experiences</TableCell>
                    <TableCell>Mr. Sunil J. Pillai, Scientist C, CWPRS, Pune, India Dr. Sarbjeet Singh, Scientist C, CWPRS, Pune, India Mr. K. Balachandran, ARO, CWPRS, Pune, India Mr. Ankit Sahu, RA, CWPRS, Pune, India Mr. Rizwan Ali, Scientist E, CWPRS, Pune, India Dr. R S Kankara, Director, CWPRS, Pune, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>122</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Using scientific method for analyzing and designing suitable  grout system for seepage reduction in Warna dam,  Maharashtra </TableCell>
                    <TableCell>VIGNESWARAN R Scientist C, CWPRS, Pune, India SUNIL J PILLAI Scientist C, CWPRS, Pune, India BALACHANDRAN ARO, CWPRS, Pune, India JAI PRAKSH MEENA RA, CWPRS, Pune, India RIZWAN ALI Scientist E, CWPRS, Pune, India Dr. R S Kankara, Director, CWPRS, Pune, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>123</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Role of Dams and Need of their rehabilitation in climate Change adoptataion </TableCell>
                    <TableCell>Ravi Solanki CE State Water Resources & Planning Devleopment , Rajasthan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>124</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Optimization Design for Plastic Concrete Mix Ratio of Cut-off Wall of Secant Pile Wall of La Gogue Dam in Seychelles</TableCell>
                    <TableCell>Li Tingyao, Liu Hongde, Chen Jing, Fan Jinhui Sinohydro Bureau 9 Co., Ltd., Guiyang, China
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam Rehabilitation & Improvement (Session 3 G) <br />
                      Dr. R. Chitra,Director CWPRS
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>125</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>New life for hydro assets â€“ Renovation, modernization, uprating and life extension of hydroelectric projects</TableCell>
                    <TableCell>Vivek Dwivedi, Shrish Dubey, Prashant Jaiswal & Suchismita Das NHPC Ltd., Faridabad, Haryana, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>126</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Hydraulic characterization of Quebradona Dam spillway (Colombia) using Computational Fluid Dynamics</TableCell>
                    <TableCell>A. Bayón Universitat Politècnica de València, Spain A. Morales Torres iPresas, València, Spain J.D. Parra Jiménez Empresas Públicas de Medellín (EPM), Colombia I. Escuder Bueno iPresas & Universitat Politècnica de València, Spain
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>127</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Estimation of Peak Flood Value for Kadra dam â€“ A Case Study of Kali River in Karnataka, India</TableCell>
                    <TableCell>Venkatesh.B, and Abhilash, R. Scientist, National Institute of Hydrology Visvesvaraya Nagar, Belagavi, Karnataka
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>128</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Dam Rehabilitation and Improvement of Barrage, Bamboo Trash rack and Power Channel of Loktak Power Station, Manipur, India.</TableCell>
                    <TableCell>Varun Agarwal NHPC Limited, Faridabad, Indi
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>129</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Finite element analysis of polyurea coating under substrate deformation and water pressure.</TableCell>
                    <TableCell>Bingqi Li China Institute of Water Resources and Hydropower Research, Beijing, China Xiaonan Liu China Institute of Water Resources and Hydropower Research, Beijing, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>130</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Technical Research on Creation of â€œSmall Environment of Concrete Pouring Surfaceâ€ for High Arch Dam in High-cold and High-altitude Area</TableCell>
                    <TableCell>ZHANG WEIWEI1, ZENG FENG1 (1. China Northwest Water Conservancy & Hydropower Engineering Consulting Co., Ltd., Xi'an, Shaanxi 710000)
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dams & Climate Change Adaptation (Session 4 A) <br />
                      Shri D.K. Sharma, Chairman, HPERC <br />
                      Dr. Denis Aelbrecht, Chairman ICOLD TC Y Climate Change, President of CFBR
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>131</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Impacts of climate change on dams and reservoirs</TableCell>
                    <TableCell>S. Perera Department of Energy, Environment and Climate Action, Victoria, Australia C.P Jolly Department of Energy, Environment and Climate Action, Victoria, Australia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>132</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>Assessing the impact of climate change on South Korean water resources and formulating adaptive response strategies</TableCell>
                    <TableCell>S. Park The University of Melbourne, Melbourne, Australia Korea Water Resources Corporation (K-water), Daejeon, Republic of Korea A. W. Western & M. C. Peel The University of Melbourne, Melbourne, Australia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>133</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Advancing Dam Safety and Risk Management Amidst Climate Change</TableCell>
                    <TableCell>Kayed I. Lakhia, AIA, NCARB, LEED AP, CFM Director, US National Dam Safety Program Federal Emergency Management Agency, Washington D.C., USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>134</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Dam safety issues during extreme hydrological events: natural or preventable disasters</TableCell>
                    <TableCell>M. Membrillera GHD, Sydney, Australia E. Martínez Self-Employee, Puerto Ordaz, Venezuela
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>135</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Adaptation measures at the system of RuÅ¾Ã­n hydraulic structures   caused by external impacts and the age of the dams</TableCell>
                    <TableCell>Roman Ivanco, Dusan Mydla & Lukas Slosar Slovak Watermanagement Enterprise, Bratislava, Slovakia Marian Miscik Slovak National Commission on Large Dams, Bratislava, Slovakia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>136</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Adaptation measures at the system of RuÅ¾Ã­n hydraulic structures   caused by external impacts and the age of the dams</TableCell>
                    <TableCell>Roman Ivanco, Dusan Mydla & Lukas Slosar Slovak Watermanagement Enterprise, Bratislava, Slovakia Marian Miscik Slovak National Commission on Large Dams, Bratislava, Slovakia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>137</TableCell>
                    <TableCell>
                      (12:45-13:00)</TableCell>
                    <TableCell>Promoting the enhancement of flood control capacities of existing dams across South Korea by reflecting increases in PMP</TableCell>
                    <TableCell>D. Kang, S. Han, W. Park & G. Kim Korea Water Resources Corporation (K-water), Daejeon, South Korea
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dams & Climate Change Adaptation (Session 4 B) <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>138</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Occurrences of extreme events and their indentation on downstream projects â€“ a case study of the Alaknanda & the Dhauliganga Valley of Uttarakhand, India</TableCell>
                    <TableCell>R.K. Vishnoi, Bhupender Gupta, Ajay Verma, Ajay Kumar, N. K. Ojha THDC India Limited, Rishikesh, Uttarakhand, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>139</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Climate change effects on dams - Finland</TableCell>
                    <TableCell>E. Isomäki Kainuu ELY Centre, Finland
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>140</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>The "2023 Flood Response" Paper of Yeongju Multipurpose Dam built to Respond to Recent Climate Change in Republic of Korea</TableCell>
                    <TableCell>Sun-Eung, Kim Senior Manager, Korea Water Resources Corporation(K-water), Yeongju, Republic of Korea
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>141</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Influence of climate change uncertainty on dam safety management</TableCell>
                    <TableCell>A. Morales-Torres iPresas Risk Analysis, Valencia, Spain J. Fluixa-Sanmartin HYDRO Exploitation, Sion, Switzerland I. Escuder-Bueno iPresas Risk Analysis and Polytechnic University of Valencia (UPV), Valencia, Spain
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>142</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Impact assessment of climate change on seasonal operation of reservoir systems in heavy snowfall area in Japan using 150-year continuous climate experiment</TableCell>
                    <TableCell>D. Nohara Kajima Technical Research Institute, Chofu, Japan Y. Sato Ehime University, Matsuyama, Japan T. Sumi DPRI, Kyoto University, Uji, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>143</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Adapting Hydropower Infrastructure to Climate Extremes: Experiences from Sultan Mahmud Power Station's Slope Rehabilitation in Response to Unprecedented Rainfall and Debris Flow Challenges</TableCell>
                    <TableCell>M.E.F Othman TNB Power Generation Sdn. Bhd., Kuala Lumpur, Malaysia J. Luis TNB Power Generation Sdn. Bhd., Kuala Lumpur, Malaysia M.M. Omar TNB Power Generation Sdn. Bhd., Kuala Lumpur, Malaysia M.F. Nazlan TNB Power Generation Sdn. Bhd., Kuala Lumpur, Malaysia L.M. Sidek Universiti Tenaga Nasional (UNITEN), Kajang, Malaysia H. Basri Universiti Tenaga Nasional (UNITEN), Kajang, Malaysia
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dams & Climate Change Adaptation (Session 4 C) <br />
                      Shri Vidyaranya Bandi,Head of International and Special Projects, BAUER Engineering India Pvt. Ltd
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>144</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Optimization of Spillways Arrangement for VPHEP in View of 2013 Flood in the River Alaknanda in Uttarakhand, India- A Case Study</TableCell>
                    <TableCell>R.K. Vishnoi, Bhupender Gupta, Atul Jain, U.D. Dangwal, Indu Pal THDC India Limited, Rishikesh (Uttarakhand), India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>145</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Dam Break Simulation Based on Mega Rainfall Scenario and Selection of Optimal Shelter</TableCell>
                    <TableCell>Kyunghun Kim Department of Civil Engineering, Inha university, 22212 Incheon, South Korea Donghyun Kim Water Resources System, Inha university, 22212 Incheon, South Korea Junhyeong Lee Department of Civil Engineering, Inha university, 22212 Incheon, South Korea Won-joon Wang Water Resources System, Inha university, 22212 Incheon, South Korea Hung Soo Kim Department of Civil Engineering, Inha university, 22212 Incheon, South Korea
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>146</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Plastic Concrete for Cut-off Walls - Sustainable Design Concept</TableCell>
                    <TableCell>Dr. Karsten Beckhaus, Dr. Vijayaranya Bandi, Bauer Engineering
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>147</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Climate change studies of Indiaâ€™s neighbouring countries and its impact on dams and water resources utilizing CMIP6</TableCell>
                    <TableCell>V. Srivastava & P. Sharma Rashtriya Raksha University, Gandhinagar, India D.K. Sharma Himachal Pradesh Electricity Regulatory Commission, Himachal Pradesh, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>148</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Relationship of Safety, ESG and Data for Risk-based Dam Safety  Assessment</TableCell>
                    <TableCell>J.Y. Lim Korea Water Resources Corporation, Daejeon, Republic of Korea N.R. Kim Korea Water Resources Corporation, Daejeon, Republic of Korea S.B. Jo Korea Water Resources Corporation, Daejeon, Republic of Korea
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>149</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Evaluating of Long-Term River Flow Forecasting Using a Hydro-Meteorological Modelling Approach (Case Study: Karkheh Dam Basin)</TableCell>
                    <TableCell>Farhad Nourmohammadi Khuzestan Water and Power Authority (KWPA), Ahvaz, Iran Ali Shahbazi Khuzestan Water and Power Authority (KWPA), Ahvaz, Iran Ali Motamedi Khuzestan Water and Power Authority (KWPA), Ahvaz, Iran Majid Delavar Department of Water Engineering and Management, Tarbiat Modares University, Tehran, Iran Hamid Reza Hajihossieni Department of Water Engineering and Management, Tarbiat Modares University, Tehran, Iran Mohammad Reza Eini Department of Hydrology, Meteorology and Water Management, Institute of Environmental Engineering, Warsaw University of Life Sciences, Warsaw, Poland Saeid Morid Department of Water Engineering and Management, Tarbiat Modares University, Tehran, Iran
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam & People(Session 5 A) <br />
                      Shri R.K. Chaudhary CMD, NHPC <br />
                      Dr Eric Peloquin
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>150</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Scropoasa Dam, Romaniaâ€™s first hydropower large dam which impresses thought itâ€™s design to this day</TableCell>
                    <TableCell>C. V. Popescu Hydraulic Engineering Department, Technical University of Civil Engineering, Bucharest, Romania L. Lungu Hydraulic Engineering Department, Technical University of Civil Engineering, Bucharest, Romania I.D. Iacob Hidroelectrica, Bucharest, Romania G. Boian Hidroelectrica, Bucharest, Romania
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>151</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>Simple and Accurate Assessments of the Danger Posed by Large Storm Events and Downstream Impacts Below Remote Dams</TableCell>
                    <TableCell>Nicholas Agnoli, P.Eng Applied Weather Associates, Monument, Colorado, USA Yogi Sookhu Applied Weather Associates, Monument, Colorado, USA Bill Kappel Applied Weather Associates, Monument, Colorado, USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>152</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Preparing for the Coming Wave of Dam Projects</TableCell>
                    <TableCell>Del A. Shannon, PE1 Vincent Mercier, P. Eng., PMP 2
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>153</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>The multipurpose water uses of hydropower reservoirs in the Lower Sava River in Slovenia</TableCell>
                    <TableCell>N. Smolar-Žvanut Ministry of Natural Resources and Spatial Planning, Slovenian Water Agency, Celje, Slovenia M. Centa Ministry of Natural Resources and Spatial Planning, Slovenian Water Agency, Celje, Slovenia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>154</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Characteristic analysis of predicted rainfall in dam basins in Japan and development of a decision-making support system for dam operation</TableCell>
                    <TableCell>Yoshimasa Morooka & Tetsuya Takeshita National Institute for Land and Infrastructure Management (NILIM) Ministry of Land, Infrastructure, Transport and Tourism (MLIT), Tsukuba-shi, Ibaraki, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>155</TableCell>
                    <TableCell>
                      (12:45-13:00)</TableCell>
                    <TableCell>Long term impacts of check dams on water security and economy of communities along Amrit Sarovars (Lakes) of Gagadiya River, Lathi Taluka, Amreli District, Gujarat State</TableCell>
                    <TableCell>Savji Dholakia1 , Brijesh Dholakia1 , Keziah Gerosano1 , Rohit Tiwari1 , V. D. Loliyana2 , Rithwik Vardhan2 , Rahul Dasari2 , S. Nambiar2 1Dholakia Foundation, Surat, India 2FAMS Design Solution Pvt. Ltd., Mumbai, India
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam & People(Session 5 B) <br />
                      Shri Harsh Baweja Director Finance REC Ltd. <br />
                      Andrew I. Bayliss MSc. P.Eng (AB, BC)Vice President
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>156</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Role of Large Multipurpose Projects in  Providing Water, Food and Energy Security</TableCell>
                    <TableCell>Manoj Tripathi, Chairman
                      C.P. Singh, Chief Engineer, BBMB
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>157</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Expansion of large gates in the Nagayasuguchi Dam remodeling project; a focus on the installation of selective water intake facilities</TableCell>
                    <TableCell>H. Okamatsu & T. Takahashi IHI Construction Service Co., Ltd., Osaka, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>158</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>The initiatives of the Italian Committee on Large Dams to promote a proactive dialogue between dam owners and stakeholders</TableCell>
                    <TableCell>A. Frigerio Ricerca sul Sistema Energetico - RSE S.p.A., Milan, Italy G. Mazzà ITCOLD - Italian National Committee on Large Dams, Rome, Italy S. Lazaridou EurCOLD - ICOLD European Club, GCOLD, Hydroexigiantiki SA, Athens, Greece
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>159</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Hydroeconomic Optimization of Citarum Cascade Reservoirs
                      A Comparative Study of Two Fundamentally Different Optimization
                      Methods in the Citarum Cascade Reservoirs Case Study</TableCell>
                    <TableCell>Tiaravanni Hermawana , Peter Gijsbers a , Marnix van der Vata 5 a Deltares, P.O. Box 177, 2600 MH Delft, The Netherlands Correspondence to: Tiaravanni Hermawan (Tiaravanni.Hermawan@deltares.nl)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>160</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>An integrated approach towards sustainable management of lake kariba for water, food and energy security balanced against the needs of the affected communities, environment and the economy</TableCell>
                    <TableCell>B. Philemon, P. Kapinga & M. L. K. Mbawo Zambezi River Authority, Lusaka, Zambia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>161</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>The U.S. Bureau of Reclamationâ€™s Dam Safety Risk Communication</TableCell>
                    <TableCell>Lan Nguyen1 Theodore Kroeger2 Timothy Tochtrop3 U.S. Bureau of Reclamation United States of America
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam & People(Session 5 C) <br />
                      Shri Manish Srikhande, HoD, IIT Roorkee</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>162</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Economic Potential of Tehri Dam for Local Community</TableCell>
                    <TableCell>Bhupender Gupta1 Director Technical, THDC India Ltd, India. L.P.Joshi 2 1Executive Director (TC&APP),THDC India Ltd, India. lpjoshi@thdc.co.in Karan Bargali3 , Ashish Dabral3 2Tehri Hydropower Plant, THDC India Ltd, India.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>163</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Building and Demolishing Dams A Multivariate Construction of Public Awareness of Reservoir Dams</TableCell>
                    <TableCell>Yang Jun 1 , Zhang Zhihui2
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>164</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>STORAGE DAM ENSURES WATER SECURITY AS WELL AS DOWNSTREAM SAFETY â€“ A CASE STUDY OF TEHRI DAM</TableCell>
                    <TableCell>Virendra Singh CGM, THDCIL, Rishikesh, India Atul Kumar Singh AGM, THDCIL, Rishikesh, India Sayyed Rubeel Naqvi Asstt. Mgr., THDCIL, Rishikesh, India Bhanu Sharma Hydrologist, THDCIL, Rishikesh, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>165</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Hydro Project: Growth Engine for Hilly Region </TableCell>
                    <TableCell>Sandeep Batra NHPC Ltd., Faridabad, India Siddhi Vinayak NHPC Ltd., Faridabad, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>166</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Research on runoff characteristics and water utilization changes in Ningxiaâ€“Inner Mongolia reach of the Upper Yellow River since the implementation of the Liujiaxia and Longyangxia reservoirs </TableCell>
                    <TableCell>Li Bin Yellow River Institute of Hydraulic Research, ZhengZhou, China Feng Qing Yellow River Institute of Hydraulic Research, ZhengZhou, China Zhang Xiaohua Yellow River Institute of Hydraulic Research, ZhengZhou, China Zheng Yanshuang Yellow River Institute of Hydraulic Research, ZhengZhou, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>167</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Analysis on causes of pre-stressed loss of Dam slope anchor</TableCell>
                    <TableCell>Xiao Shijn Survey and Design Institute of SINOHYDRO BUREAU 10 CO.,LTD，Powerchina，Chengdu, China Chen Xiaobo Survey and Design Institute of SINOHYDRO BUREAU 10 CO.,LTD，Powerchina，Chengdu, China Yao Chaohong Survey and Design Institute of SINOHYDRO BUREAU 10 CO.,LTD，Powerchina，Chengdu, China
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Integrated Reservoir Management (Basin Approach)(Session 6 A) <br />
                      Eng. Etore Funchal de Faria, Brazilian Committee on Dams (CBDB)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>168</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Sediment Yield Modeling Using SWAT: Case of Arror Dam, Kenya</TableCell>
                    <TableCell>Epari Ritesh Patro,
                      Water, Energy and Environmental Engineering Research Unit, University of Oulu, FIN-90014, Oulu, Finland
                      Amit Gautam,
                      Ph.D. Research Scholar (PT), Indian Institute of Technology, Delhi, New Delhi
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>169</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Integrated Reservoir Operation System of Mula-Mutha basin for flood analysis of Pune urban catchment</TableCell>
                    <TableCell>P.G. Gavali, J. Niyaz, S. H. Gavhale, S.S. Pokale, S. Kedia, S. Islam, G.P. Kadam, M. K. Khare, U. D. Shende Centre for Development of Advanced Computing, Pune, India P.P. Mujumdar Indian Institute of Science. Bangalore India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>170</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Opportunities and Challenges of Murum Bakun Hydro Cascade</TableCell>
                    <TableCell>J.A.J. Blandoi Sarawak Energy Berhad, Kuching, Malaysia S. Alex Sarawak Energy Berhad, Kuching, Malaysia G.C.C. Yong Sarawak Energy Berhad, Kuching, Malaysia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>171</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Optimal operating policy for the Parambikulam Aliyar multireservoir system using simulation optimization model</TableCell>
                    <TableCell>K.A.Joshy1
                      , B. Sridharan2 and K. Srinivasan3
                      1Research Scholar, Indian Institute of Technology, Madras, Chief Engineer (Rtd.), Kerala Water
                      Resources Department;
                      2Assistant Professor, Indian Institute of Technology Indore
                      3Professor (Rtd.), Indian Institute of Technology Madras
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>172</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Hydrological Management, operation of Kariba Reservoir and Catchment</TableCell>
                    <TableCell>B.B. Mwangala
                      Zambezi River Authority, Lusaka, Zambia
                      S. Mwale
                      Zambezi River Authority, Lusaka, Zambia
                      N C. Chisense
                      Zambezi River Authority, Lusaka, Zambia
                      M.C. Munodawafa
                      Zambezi River Authority, Lusaka, Zambia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>173</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Integrated reservoir operations for optimal flood control, water supply, and hydropower generation</TableCell>
                    <TableCell>Michael Thiemann
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>174</TableCell>
                    <TableCell>
                      (15:30-15:45)</TableCell>
                    <TableCell>Forecasting, Analytics, Modelling and Simulation for Flood Inundation Mapping: Big Data Analytics for Water-Climate-Agriculture-Energy Security</TableCell>
                    <TableCell>V. D. Loliyana1 , S. Nambiar2 1Sr. Consultant – Agriculture & Allied Sectors, Maharashtra Institutation for Transformation (MITRA), Mumbai, India 2 FAMS Design Solution Private Limited, Mumbai - 400042
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Integrated Reservoir Management (Basin Approach)(Session 6 B) <br />
                      Mr. Hideki Hirai, President, JCOLD</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>175</TableCell>
                    <TableCell>
                      (15:30-15:45)</TableCell>
                    <TableCell>Integrated Reservoir Management of Large Reservoirs</TableCell>
                    <TableCell>Manoj Tripathi, Chairman,
                      C.P. Singh, Chief Engineer2
                      Sukhdeep Singh, Joint Secretary
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>176</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Development of a dam operation support system for flooding using artificial intelligence technology</TableCell>
                    <TableCell>S.Takino, T. Teshigawara & T.Maekawa Tokyo Electric Company Holdings, Inc., Tokyo, Japan K.Kojima & H.Shionoya TEPCO Renewable Power, Inc., Tokyo, Japan T.Onishi NEC Corporation, Tokyo, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>177</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Initiatives related to smart operation and utilization of existing dams in Japan</TableCell>
                    <TableCell>T. Sumi*1 & T. Matsubara*2 Subcommittee of JCOLD on the smart utilization and operation of existing dams, Tokyo, Japan *1 Program Specific Professor, Dam Upgrading and Sediment Environment Restoration Engineering, Water Resources Research Center, Disaster Prevention Research Institute, Kyoto University *2 Acting Director, Dam Upgrading Promotion Office, Civil and Architectural Engineering Dept, Electric Power Development Co., Ltd., Tokyo, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>178</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Enhancing flood control and hydropower capacity through the implementation of long-term rainfall forecasts</TableCell>
                    <TableCell>T. Tanaka, T. Endo JICE, Tokyo, Japan K. Kido, T. Sano Japan Water Agency, Saitama, Japan Y. Michihiro Japan Weather Association, Tokyo, Japan T. Sumi Kyoto University, Kyoto, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>179</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Geological and hydrogeological investigation of reservoir for leakage: Silvan dam, southeastern Turkey</TableCell>
                    <TableCell>D. Aydoğan Devlet Su İşleri Genel Müdürlüğü, Ankara, Türkiye A. Koçbay Devlet Su İşleri Genel Müdürlüğü, Ankara, Türkiye O. Şimşek Fugro Sial, Ankara, Türkiye A.E. Ayan Fugro Sial, Ankara, Türkiye T.Y. Duman Fugro Sial, Ankara, Türkiye E. Aydeniz Atay & BTS JV, Ankara, Türkiye
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>180</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Simulating the condition of the Dez dam reservoir and monitoring the reservoirs in daily, 10-day and monthly intervals.</TableCell>
                    <TableCell>ARASH.BAGHALNEJAD KWPA,AHWAZ,IRAN
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>181</TableCell>
                    <TableCell>
                      (17:30-17:45)</TableCell>
                    <TableCell>Generating reservoir operating rules using mathematical optimization with a note on the performance criteria and a case study</TableCell>
                    <TableCell>M. Khalafallah IHE Delft Institute for Water Education, Delft, The Netherlands N. Ilich www.optimal-solutions-ltd.com, currently on assignment with SMEC India Pvt., New Delhi S. Giri Royal HaskoningDHV, Rotterdam, The Netherlands T. N. Bhattarai IHE Delft Institute for Water Education, Delft, The Netherlands T. Kawamoto Asian Development Bank, Manila, Philippines
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Environmental & Social Aspects(Session 7 A) <br />
                      Shri M.A.K.P. Singh, Ex. Member Hydro, CEA</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>182</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Rastolita Dam, a deferred project that bridges generations, reanalyzed according to modern standards</TableCell>
                    <TableCell>C.V. Popescu
                      ROCOLD, Bucharest, Romania
                      D. Covaceanu
                      ROCOLD, Bucharest, Romania
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>183</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>Recent Advances in Modelling the Failure of Tailings Dams</TableCell>
                    <TableCell>G. Petkovsek & M. Hassan HR Wallingford Ltd, Wallingford, United Kingdom H. McLeod & S. Liu Klohn Crippen Berger, Vancouver, Canada
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>184</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Clean rivers start at your dam, the economic and environmental feasibility of stemming plastic pollution at dams</TableCell>
                    <TableCell>J McCully Captain US Army, Canton, Ohio, USA Director of the Clean Rivers Initiative, Worthington Products P Meeks President, Worthington Products Inc, Canton, Ohio, USA
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>185</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Consideration of physical and biological environment changes assuming sediment replenishment downstream of Futatsuno dam</TableCell>
                    <TableCell>H. Iseki, N. Okamoto, N. Tabuchi & H. Ichiyanagi Water Resources Environment Center, Chiyoda-ku, Tokyo, Japan I. Katano Nara Women’s University, Nara-shi, Nara, Japan K. Morimoto Electric Power Development Co., Ltd., Chuo-ku, Tokyo, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>186</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Case study of biomass clearance and alternative fishery creation in large scale of dam reservoir</TableCell>
                    <TableCell>T. Yamamoto & K. Nakamura The Kansai Electric Power Co., Inc., Osaka, Japan K. Tomioka Former Nam Ngiep 1 Power Company, Vientiane, Lao PDR S. Tsutsui NEWJEC Inc., Osaka, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>187</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Development of an efficient survey method using environmental DNA to assess fish fauna in dam reservoirs in Japan</TableCell>
                    <TableCell>Tomonori Osugi Water Resources Environment Center, Tokyo, Japan Jiro Okitsu OYO Corporation, Koriyama, Japan Toshifumi Minamoto Kobe University, Kobe, Japan Hidetaka Ichiyanagi, Takami Adachi & Taku Kodaira Water Resources Environment Center, Tokyo, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Environmental & Social Aspects(Session 7 B) <br />
                      Shri Arun Kumar, IIT Rookree</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>188</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>Sustainable Sediment Management of Reservoirs using fully automated Dredges combined with Methane Gas Collectors </TableCell>
                    <TableCell>Lara Gehrmann Hülskens Sediments, Wesel, Germany Thomas Gross Hülskens Sediments, Wesel, Germany
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>189</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>Flood Resilient Landscapes</TableCell>
                    <TableCell>F.P.W.van den Berg Deltares. Delft, The Netherlands N. Moghtaderi Asr Deltares. Delft, The Netherlands M.A.Van Deltares. Delft, The Netherlands
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>190</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Economical & sustainable methods in geotechncial Engineering using Deep Soil Mixing Technique</TableCell>
                    <TableCell>Fadi Hadda, Vidyaranya Bandi
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>191</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>High dams in mountainous regions: environmental-friendly replication of
                      natural phenomena
                    </TableCell>
                    <TableCell>Alexander Strom, Oleg Rubin, Anton Antonov Research Institute of Energy Structures – branch of JSC JSC "Hydroproject Institute", Moscow, Russia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>192</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>CHALLENGES IN HARNESSING HYDRO-POTENTIAL AVAILABLE IN ENVIRONMENTAL FLOW (E-FLOW) AT THE EXISTING DAM INFRASTRUCTURE ENSURING UN-INTERUPTED E-FLOW IN THE RIVER
                    </TableCell>
                    <TableCell>MILIND GANESH GOKHALE, NIRAJ KUMAR SINGH, MITESH KUMAR*, ALOK YADAV NHPC LIMITED
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>193</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Integrating computational fluid dynamics and automation for optimal design of fish passage structures</TableCell>
                    <TableCell>Anurag Chandorkar Flow Science Software Pvt Ltd, Pune, India Pankaj Lawande Flow Science Software Pvt Ltd, Pune, India John Wendelbo Flow Science Inc, Santa Fe, USA
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Evolving with Modern Technology for Construction of dams(Session 8 A) <br />
                      Dr. Manoj Kumar Chauhan, Head Water & Urban Development, South Aisa, Surbanajurong Group <br />
                      Prof. Dr. Ignacio Escuder Bueno,UPV Universitat Politècnica de València
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>194</TableCell>
                    <TableCell>
                      (09:00-09:15)</TableCell>
                    <TableCell>Characteristics of initial micro-cracks in low heat Portland cement dam concrete cured onsite in xerothermic valley</TableCell>
                    <TableCell>S.G. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China Department of Materials, China Institute of Water Resources and Hydropower Researc, Beijing, China Y.M. Yin Department of Materials, China Institute of Water Resources and Hydropower Researc, Beijing, China G.X. Chen Department of Materials, China Institute of Water Resources and Hydropower Researc, Beijing, China W.W. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China D.J. Rong Henan Yudong Water Conservancy Guarantee Center, Kaifeng, China H. Wang Henan Yangtze-to-Huaihe Water Diversion CO.LTD., Zhengzhou, China R.T. Du Henan Yangtze-to-Huaihe Water Diversion CO.LTD., Zhengzhou, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>195</TableCell>
                    <TableCell>
                      (09:15-09:30)</TableCell>
                    <TableCell>A holistic approach to understanding ground conditions: utilising historic and contemporary data for enhanced dam construction and maintenance</TableCell>
                    <TableCell>R. Lowries Seequent, Christchurch, New Zealand F. Hamidi Seequent, Brisbane, Australia A. Hollingsworth Seequent, Brisbane, Australia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>196</TableCell>
                    <TableCell>
                      (09:30-09:45)</TableCell>
                    <TableCell>Digital transformation (DX) in construction. Digital twin supervision at Kawakami Dam project</TableCell>
                    <TableCell>M.Omata & K.Ueko Obayashi Corporation, Tokyo, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>197</TableCell>
                    <TableCell>
                      (09:45-10:00)</TableCell>
                    <TableCell>Dam building and rehabilitation with electric and low carbon solutions in western Norway</TableCell>
                    <TableCell>H. Lindstad & R. S. Kjønås Norconsult Norge AS, Bergen, Norway
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>198</TableCell>
                    <TableCell>
                      (10:00-10:15)</TableCell>
                    <TableCell>Seepage mitigation by excavated cut-off methods at dam & dikes worldwide</TableCell>
                    <TableCell>Michael Baltruschat, Stefan, Jaeger, Endre Balogh, Hemanth Narayanan, Ashraf Wahby, Cyril Bou-sleiman, Vidyaranya Bandi, Yogeshwaran Purushothaman
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>199</TableCell>
                    <TableCell>
                      (10:15-10:30)</TableCell>
                    <TableCell>Long-term properties of low-heat Portland cement dam concrete</TableCell>
                    <TableCell>H.M. Yang Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China S.G. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China H.T. Wu Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China W.W. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China K.L. ZHANG Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China S.J. ZHANG Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Evolving with Modern Technology for Construction of dams(Session 8 B) <br />
                      Shri Rajesh Pandey Director, Dynasoure Concrete Treatment Pvt. Ltd.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>200</TableCell>
                    <TableCell>
                      (11:30-11:45)</TableCell>
                    <TableCell>Demolition, Dismantling and Integration of large concrete structures in Hydro-Power Projects</TableCell>
                    <TableCell>Vishal Kumar Saini, Shrish Dubey, Meena Raj NHPC Ltd., Faridabad, Haryana, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>201</TableCell>
                    <TableCell>
                      (12:00-12:15)</TableCell>
                    <TableCell>Leveraging Building Information Modelling (BIM) and Drone Technology for Enhanced Construction Progress Monitoring and Mitigation of Dam Subsidence Risks</TableCell>
                    <TableCell>S. Das PMDCS, Jalpaiguri, West Bengal, India S.K. Das PMDCS, Jalpaiguri, West Bengal, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>202</TableCell>
                    <TableCell>
                      (11:45-12:00)</TableCell>
                    <TableCell>Right materials for the construction of concrete dams</TableCell>
                    <TableCell>S.C.Maiti S.C.Maiti & Associates, New Delhi, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>203</TableCell>
                    <TableCell>
                      (12:15-12:30)</TableCell>
                    <TableCell>Comparative analysis of hydration characteristics and microstructure of moderate-heat and low-heat Portland cement</TableCell>
                    <TableCell>W.W. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China J.Y. Cui Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China D. Tian Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China M. Jing School of Material Science and Engineering, Southeast University, Nanjing, China S.G. Li Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China H.M. Yang Hydraulic Concrete Institute, China Three Gorges Corporation, Beijing, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>204</TableCell>
                    <TableCell>
                      (12:30-12:45)</TableCell>
                    <TableCell>Discussion on Vibroflotation Gravel Pile Construction Technology & Quality Control Measures in Yingliangbao Hydropower Plant</TableCell>
                    <TableCell>ZHAO XIPING1, LI XIAOWEI1 (1. China Northwest Water Conservancy & Hydropower Engineering Consulting Co., Ltd., Xi'an, Shaanxi 710000)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>205</TableCell>
                    <TableCell>
                      (12:45-13:10)</TableCell>
                    <TableCell>Evolution of the Microstructure of Low-heat Portland Cement Concrete in Two Years</TableCell>
                    <TableCell>Huite Wu China Three Gorges Corporation, Beijing, China Huamei Yang China Three Gorges Corporation, Beijing, China Wenwei Li China Three Gorges Corporation, Beijing, China Shuguang Li China Three Gorges Corporation, Beijing, China Xinyu Li China Three Gorges Corporation, Beijing, China
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam & Renewable Energy(Session 9 A) <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>206</TableCell>
                    <TableCell>
                      (14:00-14:15)</TableCell>
                    <TableCell>Development of pumped storage hydropower in Maharashtra state, India- Challenges and policy framework for sustainability</TableCell>
                    <TableCell>N.G.Bhoir Superintending Engineer, Koyna Design Circle, Pune, Water Resources Department, Government of Maharashtra, India.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>207</TableCell>
                    <TableCell>
                      (14:15-14:30)</TableCell>
                    <TableCell>First Large Renewable Energy Project in india using Geomembrane system</TableCell>
                    <TableCell>G. Vaschetti & M. Scarella Carpi Tech, Balerna, Ticino, Switzerland Jagadeesan Subramanian Carpi India, Chennai, Tamil Nadu, India
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>208</TableCell>
                    <TableCell>
                      (14:30-14:45)</TableCell>
                    <TableCell>Pumped storage in the context of energy transition-where do we stand?</TableCell>
                    <TableCell>S.C. Mittal, Nakul Yadav DMR Hydroengineering and Infrastructures Ltd, Faridabad, India Marine BERNICOT ISL Ingenierie, France
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>209</TableCell>
                    <TableCell>
                      (14:45-15:00)</TableCell>
                    <TableCell>Optimizing the design of water intakes in existing reservoirs for PSH projects using numerical modeling</TableCell>
                    <TableCell>C. Granell Spanish Commission on Large Dams, Granell Hydraulic Engineers, Madrid, Spain F. Hernando Iberdrola, Madrid, Spain J. Milla Granell Hydraulic Engineers, Madrid, Spain
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>210</TableCell>
                    <TableCell>
                      (15:00-15:15)</TableCell>
                    <TableCell>Review and prospects of renewable hybrid energy</TableCell>
                    <TableCell>CHEN Guanfu, WANG Xinhuai, LI Sisheng POWERCHINA International Group Limited, Beijing, China
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>211</TableCell>
                    <TableCell>
                      (15:15-15:30)</TableCell>
                    <TableCell>Economic performance and its availability of pumped storage
                      hydropower under the carbon neutrality</TableCell>
                    <TableCell>M. Kashiwayanagi & T. Tobase
                      Electric Power Development Co., Ltd. Chigasaki, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>212</TableCell>
                    <TableCell>
                      (15:30-15:45)</TableCell>
                    <TableCell>Tehri Dam: From Legacy Hydro Power to Sustainable Energy Hub via Pumped Storage</TableCell>
                    <TableCell>Bhupender Gupta1 1Director Technical, THDC India Ltd, India. L.P. Joshi2 2Executive Director (TC&APP), THDC India Ltd, India. lpjoshi@thdc.co.in Karan Bargali3 , Shubhendu Raghav Mishra3 3Tehri Hydropower Plant, THDC India Ltd, India.
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                      Dam & Renewable Energy(Session 9 B) <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>213</TableCell>
                    <TableCell>
                      (16:00-16:15)</TableCell>
                    <TableCell>The importance of pumped hydro in Australia's energy transition</TableCell>
                    <TableCell>R. Herweynen Entura, Hobart, Australia N. West Entura, Hobart, Australia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>214</TableCell>
                    <TableCell>
                      (16:15-16:30)</TableCell>
                    <TableCell>New operation rule for increasing power generation by utilizing late flood discharge at Hitokura Dam</TableCell>
                    <TableCell>K. Sakamoto Japan Water Agency, Hitokura Dam Operation and Maintenance Office, Hyogo, Japan H. Sasahara, S. Naito & H.Soda Japan Water Agency, Headquarters, Saitama, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>215</TableCell>
                    <TableCell>
                      (16:30-16:45)</TableCell>
                    <TableCell>Sustainable construction technique for the maintenance works to Chilla HEP, Uttarakhand incorporating CCGM lining</TableCell>
                    <TableCell>Amruth Chand Signet EnerTech Pvt Ltd, Mumbai, India Dr Charmaine Cheah Huesker Australia Pty Ltd, Queensland, Australia Graham Thomson Huesker Asia Pacific Pte Ltd, Singapore, Singapore
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>216</TableCell>
                    <TableCell>
                      (16:45-17:00)</TableCell>
                    <TableCell>Evaluation of the practicality of prior release by power generation using rainfall ensemble forecast for the Kurobe Dam</TableCell>
                    <TableCell>H. Naka, Y. Otsubo, & T. Arimitsu The KANSAI Electric Power Co., Inc., Osaka, Japan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>217</TableCell>
                    <TableCell>
                      (17:00-17:15)</TableCell>
                    <TableCell>Optimizing the Benefits of Reservoirs with Floating Photovoltaic  (Case Study: Cirata Dam)</TableCell>
                    <TableCell>A. Rinaldi* Technical Implementation Unit for Dam Engineering, Ministry of Public Works and Housing, South Jakarta, Indonesia * Email: aris.rinaldi@pu.go.id R. Taruli PT PLN Nusantara Power Unit Pembangkitan Cirata, West Bandung, Indonesia H. Pradipta Putra PT PLN Nusantara Power Unit Pembangkitan Cirata, West Bandung, Indonesia A. F. Firman Member of Indonesian Dam Safety Commission, South Jakarta, Indonesia R. A. Fajar Department of Civil Enginering and Earth Science, Banjarmasin State Polytechnic, Banjarmasin, Indonesia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>218</TableCell>
                    <TableCell>
                      (17:15-17:30)</TableCell>
                    <TableCell>Creating pumped hydro energy storage atlases to understand opportunities: Examples from Australia and India</TableCell>
                    <TableCell>P. Kumar Entura, New Delhi, India R. Herweynen Entura, Hobart, Australia
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>219</TableCell>
                    <TableCell>
                      (17:30-17:45)</TableCell>
                    <TableCell>Switzerland pioneering the development of pumped storage hydro schemes: past and recent advances, future perspectives.</TableCell>
                    <TableCell>Sélim Michel Sayah
                      Lombardi Engineering Ltd, Switzerland
                      Adil Qureshi
                      Lombardi Engineering India Pvt. Ltd
                      Urs Müller
                      IM Maggia Engineering Ltd, Switzerland
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>


          </Grid>
        </Grid>
        <Divider style={{ width: '100%', backgroundColor: '#efbb20', height: '1.5px', marginTop: "10px" }} />
        <Grid item xs={12} sm={6} sx={{ paddingTop: '10px', width: '100%', px: { xs: 2, sm: 10 } }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            For Every Day
          </Typography>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              11:00 - 11:30
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Morning tea
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              13:00 - 14:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Lunch
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{ mr: 10 }}>
              15:30 - 16:00
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }} style={{ fontWeight: 'bold' }}>
                Evening Tea
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailedProgram;
