import React from 'react';
import { Divider, Grid, Typography, Box } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Divider
        style={{
          height: '10px',
          backgroundColor: 'gold',
        }}
      />
      <Box
        sx={{
          width: '100%',
          padding: isMobile ? '10px' : '20px', // Adjust padding for mobile screens
          backgroundColor: '#1f1f2d',
          boxSizing: 'border-box', // Ensure padding is included in width calculation
          overflow: 'hidden', // Handle potential overflow
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            margin: '0 auto',
            maxWidth: '1200px', // Limit the maximum width for large screens
            boxSizing: 'border-box', // Ensure padding is included in width calculation
          }}
        >
          <Grid item xs={12} sm={4}>
            <Typography
              variant={isMobile ? 'h6' : 'h5'} // Adjust typography variant based on screen size
              gutterBottom
              sx={{ color: '#91959c' }}
            >
CONFERENCE SECRETARIAT
            </Typography>
            <Typography
              paragraph
              sx={{ color: '#91959c' }}
            >
             Content for the First container goes here.
            </Typography>
            {/* <Typography
              paragraph
              sx={{ color: '#91959c' }}
            >
              E-mail: <a href="mailto:icold2023@meetx.se" style={{ color: '#91959c', textDecoration: 'none' }}>icold2023@meetx.se</a>
            </Typography> */}
            {/* <Typography
              paragraph
              sx={{ color: '#91959c' }}
            >
              Phone: +46 31 708 86 90
            </Typography> */}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant={isMobile ? 'h6' : 'h5'} // Adjust typography variant based on screen size
              gutterBottom
              sx={{ color: '#91959c' }}
            >
            IMPORTANT DATES
            </Typography>
            <Typography
              paragraph
              sx={{ color: '#91959c' }}
            >
              Content for the second container goes here.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              variant={isMobile ? 'h6' : 'h5'} // Adjust typography variant based on screen size
              gutterBottom
              sx={{ color: '#91959c' }}
            >
           HOSTS
            </Typography>
            <Typography
              paragraph
              sx={{ color: '#91959c' }}
            >
              Content for the third container goes here.
            </Typography>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <Box
          sx={{
            textAlign: 'center',
            marginTop: '20px',
            paddingTop: '10px',
            borderTop: '1px solid #91959c', // Optional: border to separate copyright from content
            backgroundColor:'#36393d',
            minHeight:'30px'
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: '#91959c' }}
          >
            © Copyright - ICOLD-CIGB2024
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
