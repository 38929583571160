import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box'; 
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useTheme, useMediaQuery, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    return (
        <Paper elevation={3} style={{ padding: isMobile ? '100px' : '200px' }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center" 
                style={{
                    margin: isMobile ? '0' : '0 10px', 
                    boxSizing: 'border-box',
                }}
            >
                <Grid item xs={12}>
                    <h2 style={{ fontWeight: 'normal', textAlign: isMobile ? 'center' : 'left' }}>DETAILED PROGRAM</h2>
                    <Divider style={{ margin: '35px 0', width: isMobile ? '50%' : '6%', backgroundColor: '#efbb20', height: '1.5px' }} />
                    <p style={{ lineHeight: '2.5', textAlign: isMobile ? 'center' : 'left', color: '#006aa7', fontWeight: 'bold' }}>
                        All sessions will be in English. <br />
                        The General Assembly on Thursday will be bilingual. Simultaneous translation to English and French will be available.
                    </p>

                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <h3 style={{
                            color: '#007acc',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#f5f5f5',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                            Symposium 
                        </h3>
                        <p style={{ marginTop: '15px', color: '#333' }}>
                            You can find information about the Symposium.
                        </p>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ marginTop: '15px',background:"#436690" }}
                            onClick={() => navigate('/ProgramDetails')}
                            startIcon={<MenuBookIcon />} 
                        >
                            Detailed Program 
                        </Button>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                            FRIDAY - 27.09.2024
                        </h3>
                    </Box>
             
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>09:00 - 18:00: ICOLD Board Meeting - Hotel Le Meridien-Club Lounge 20th Floor,Connaught Place, New Delhi.</li>
                            <li>08:00 - 17:00: Short Course on "Surveillance and Monitoring for Tailings and Embankment Dams" by ICOLD Committees L & Q - "CBIP Building, Conference Hall (1st Floor),Malcha Marg, Chanakyapuri,New Delhi".</li>
                            <li>19:30 - 22:00: Dinner of ICOLD Board, INCOLD Representative & Accompanying Person. - Restaurant Miso Sexy, 2nd floor,Ambawatta,Kaldas Marg,Mehrauli,New Delhi</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                        Saturday - 28.09.2024 			
                        </h3>
                    </Box>
              
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>09:00 - 13:00: ICOLD Board Meeting - Hotel Le Meridien-Inspire Hall,Connaught Place,New Delhi.</li>
                            <li>08:00 - 17:00: Short Course on Surveillance and Monitoring for Tailings and Embankment Dams by ICOLD Committees L & Q - "CBIP Building, Conference Hall (1st Floor),Malcha Marg,Chanakyapuri,New Delhi".</li>
                            <li>14:30 - 16:30: ICOLD Board & Technical Committee Chairman's Meeting - Hotel Le Meridien-Inspire Hall, Connaught Place,New Delhi.</li>
                            <li>17:00 - 18:00: ICOLD Board & Local Organizing Committee (LOC) Meeting - Hotel Le Meridien-Inspire Hall, Connaught Place,New Delhi.</li>
                            <li>19:00 - 22:00: Dinner of ICOLD Board, LOC, Technical Committee Chairman's & Accompanying Persons - Hotel Le Meridien-Inspire Hall, Connaught Place,New Delhi.</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                             Sunday - 29.09.2024 			
                        </h3>
                    </Box>
                  
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>08:00- 17:00: Registration - Exhibition Hall No. 14 (GF),ITPO.</li>
                            <li>12:30 - 13:30: Technical Exhibition Opening - Exhibition Hall No. 14(GF),ITPO.</li>
                            <li>13:30 - 18:00: Technical Exhibition Continued (Day 1) - Exhibition Hall No. 14(GF), ITPO.</li>
                            <li>09:00 - 18:30: ICOLD Technical Committee Workshops & Meetings - Room 6-10,13,15,16,17,18,19,Bharat Mandapam, ITPO.</li>
                            <li>09:00 - 13:00: Workshop By Young Professional Forum - Room No.15, Bharat Mandapam,ITPO.</li>
                            <li>17:00 - 19:00: Young Professional Forum Meeting - Room No.7,Bharat Mandapam,ITPO.</li>
                            <li>17:00 - 18:30: Workshop on World Declaration on the role of dam in the Energy Transition & Climate Change - Room 6, Bharat Mandapam,ITPO.</li>
                            <li>19:00 - 22:00: Welcome Reception - Exhibition Hall No.14(GF),ITPO.</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                            Monday - 30.09.2024 					
                        </h3>
                    </Box>
                
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>08:00- 17:00: Registration - Exhibition Hall No.14 (GF),ITPO.</li>
                            <li>09:00 - 18:00: Technical Exhibition Continued (Day 2). - Exhibition Hall No.14(GF),ITPO.</li>
                            <li>09:00 - 19:30: ICOLD Technical Committee Meetings. - Room 5-19,Business Hall 4A,4B,4C Bharat Mandapam,ITPO.</li>
                            <li>17:00 - 19:00: Regional Club Meetings (4 nos.) 4 parallel sessions. - Room 6,7,18,19,Bharat Mandapam,ITPO.</li>
                            <li>19:30 - 22:00: ICOLD Board Specific Reception. - T.B.A</li>
                            <li>19:30 - 22:00: European Club network event. - T.B.A</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                            Tuesday - 01.10.2024 								
                        </h3>
                    </Box>
                  
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>08:00- 17:00: Registration - Exhibition Hall No.14 (GF),ITPO.</li>
                            <li>09:00 - 18:00: Technical Exhibition Continued (Day 3). - Exhibition Hall No.14 (GF),ITPO.</li>
                            <li>09:00 - 11:00: Symposium Opening Ceremony. - L2 Audi- II,Bharat Mandapam,ITPO.</li>
                            <li>11:30 - 12:30: Press Conference. - Room No.15, Bharat Mandapam,ITPO.</li>
                            <li>11:30 - 18:00: Symposium Continued (Day 1)-parallel Sessions. - Room No.6,7,18,19,9,Bharat Mandapam,ITPO</li>
                            <li>14:00 - 17:30: INCOLD Workshops -  (Dam Repairs & Rehabilitation- Deciding Design & Safety Criteria) in collaboration with CWC/DRIP. - L2 Audi- II,Bharat Mandapam,ITPO</li>
                            <li>13:30 - 15:30: Workshop on Derna & Bu Mansur Dam Failure-Libya - Room No.15,Bharat Mandapam,ITPO.</li>
                            <li>15:30 - 17:00: Workshop on "African Regional Initiative" - Room No.15,Bharat Mandapam,ITPO.</li>
                            <li>17:00 - 18:30: Francophone Delegate Meetings - Room No.15,Bharat Mandapam,ITPO.</li>
                            <li>11:00 - 18:00: Poster Session - Exhibition Hall No.14(GF),ITPO.</li>
                            <li>19:30 - 22:00: Cultural Evening - Open Air Amphitheatre,Level 2,Bharat Mandapam,ITPO.</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                            Wednesday - 02.10.2024 											
                        </h3>
                    </Box>
               
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>08:00 - 17:00: Registration - Exhibition Hall No.14(GF),ITPO.</li>
                            <li>09:00 - 14:00: Technical Exhibition (Day 4). - Exhibition Hall No.14(GF),ITPO.</li>
                            <li>09:00 - 18:00: Symposium Continued (Day 2). - Room 6,7,18,19,9,,Bharat Mandapam,ITPO.</li>
                            <li>09:00 - 13:00: INCOLD Workshops-(Application of Geosynthetics in Dam Engineering). - Room 9,Bharat Mandapam,ITPO.</li>
                            <li>09:00 - 13:00: INCOLD Workshops - (Risk Informed Dam Safety Management) in collaboration with NDSA. - Room 10, Bharat Mandapam, ITPO.</li>
                            <li>14:00 - 18:00: INCOLD Workshop (Design TSF for Closure) in collaboration with ICOLD Technical Committee 'L'. - Room 10, Bharat Mandapam, ITPO.</li>
                            <li>09:00 - 18:00: Poster Session. - Exhibition Hall No. 14(GF), ITPO.</li>
                            <li>19:00 - 22:00: ICOLD Board Specific Reception. - T.B.A.</li>
                            <li>19:00 - 22:00: Social night & Networking event for Young Professionals. - Exhibition Hall No.14(GF),ITPO.</li>
                        </ul>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            marginTop: '30px',
                            padding: '15px',
                            backgroundColor: '#436690',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            width: isMobile ? '90%' : 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                       
                        <Box
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '50%',
                                height: '2px',
                                background: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                            }}
                        />
                        <h3 style={{
                            color: 'white',
                            margin: '0',
                            fontSize: isMobile ? '1.5rem' : '1.2rem',
                            position: 'relative',
                            zIndex: 1,
                            backgroundColor: '#436690',
                            display: 'inline',
                            padding: '0 10px',
                        }}>
                        Thursday - 03.10.2024 													
                        </h3>
                    </Box>
              
                    <Box
                        style={{
                            marginTop: '20px',
                            color: '#333',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                            fontWeight: 'normal',
                        }}
                    >
                        <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>08:00 - 17:00: Registration - Exhibition Hall No. 14 (GF),ITPO.</li>
                            <li>09:00 - 18:00: ICOLD General Assembly Meeting. - Leaders Lounge, Level 2, Bharat Mandapam,ITPO.</li>
                            <li>09:00 - 13:00: INCOLD Special Session on Innovative Financing of Dams Projects in collaboration with World Bank. -Room 6, Bharat Mandapam, ITPO.</li>
                            <li>09:00 - 18:00: INCOLD Workshop - (Pumped Hydropower Storage - The need to support high penetration of renewable energy). - Room 7, Bharat Mandapam, ITPO.</li>
                            <li>09:00 - 18:00: INCOLD Workshop - (Numerical analysis of dams). - Room 18, Bharat Mandapam, ITPO.</li>
                            <li>09:00 - 18:00: INCOLD Workshop - (Sedimentation Management in Reservoirs for Sustainable Development). - Room 19, Bharat Mandapam, ITPO</li>
                            <li>08:00 - 12:00: Workshops on "Bulletin 194 - Tailings Dams Safety" by  Technical Committee "L. - "CBIP Building, Conference Hall (1st Floor),Malcha Marg, Chanakyapuri,New Delhi"</li>
                            <li>13:00 - 17:00: Short Courses on "Tailings Dam Breach Analysis" by Canadian Dam Association. - "CBIP Building, Conference Hall (1st Floor),Malcha Marg, Chanakyapuri,New Delhi"</li>
                            <li>19:00 - 22:30: Closing Ceremony & Farewell Dinner. - Exhibition Hall No. 14(GF), ITPO</li>
                        </ul>
                    </Box>
                    <Divider style={{ margin: '35px 0', width:'100%', backgroundColor: '#efbb20', height: '1.5px' }} />
                    <h3>For Each Day</h3>
                    <ul style={{
                            listStyleType: 'disc', 
                            paddingLeft: isMobile ? '20px' : '30px', 
                            margin: 0, 
                            lineHeight: '2.5'
                        }}>
                            <li>Morning tea @ 11:00 - 11:30.</li>
                            <li>Lunch @ 13:00 - 14:00.</li>
                            <li>Evening Tea @ 15:30 - 16:00.</li>
                        </ul>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Home;
